import React, { } from "react";
// import User from "./User";
// import { useAuth0 } from "@auth0/auth0-react";
// import { useDisclosure } from "@chakra-ui/react";
// import { Row, Col } from "react-bootstrap";
import { useClerk } from "@clerk/clerk-react";
import {
  Card,
  Flex,
  CardBody,
  Grid,
  GridItem,
  // Button
  // Stat,
  // StatArrow,
  // StatLabel,
  // StatHelpText,
  // StatNumber,
  // useUpdateEffect,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalCloseButton,
  // ModalBody,
} from "@chakra-ui/react";

// import FirstRun from "./FirstRun";

// import { AddIcon } from "@chakra-ui/icons";
// import { SocialIcon } from "react-social-icons";
// import { useHistory } from "react-router-dom";
import { useSelector  } from "react-redux";
// import YoutubeStats from "../components/socialProfiles/YoutubeStats";
// import FacebookProfile from "../components/socialProfiles/FacebookProfile";
// import SpotifyProfile from "../components/socialProfiles/SpotifyProfile";
// import TikTokProfile from "../components/socialProfiles/TikTokProfile";
// import RadialChart from "../components/charts/RadialChart";
// import axios from "axios";
// import { updateSocials } from '../features/profile/profileSlice'
// import { getConfig } from "../config";

import "./User.css";
import "./Home.css";
import SocialManager from "../components/socialProfiles/SocialManager";
// import TagManager from "../components/TagManager";
// import PhylloAccounts from "../components/Phyllo/PhylloAccounts";
// import { updatePhylloUser } from "../features/phyllo/phylloSlice";

// import HeatMap from "../components/charts/HeatMap";
function Home(props) {
  // const { onOpen } = useDisclosure();
  //   // const [checked, setChecked] = useState(false);
  // const [socialNetworkPicker, setSocialNetworkPicker] = useState('youtube');
  // const dispatch = useDispatch();

  // // const [activeTab, setActiveTab] = useState('1');
  // const [socialHandle, setSocialHandle] = useState("");
  // const [tempChannelData, setTempChannelData]=useState(null);

  // const history = useHistory();
  const socials = useSelector((state) => state.profile.socials);
  const { user } = useClerk();

  // const hasCreatorInfo = useSelector((state) => state.profile.hasCreatorInfo);
  // const config = useSelector((state) => state.config.keys);


  

  // useEffect(() => {
  //   // const checkPhylloUser = (uid) =>{
  //   //   console.log("checking for phyllo id: " + uid);
  //   //   axios({
  //   //           method: "POST",
  //   //           url: config.API_SERVER + "/api/phyllo/checkUser",
  //   //           headers: {
  //   //             "Content-Type": "application/json",
  //   //             "Access-Control-Allow-Origin": "*",
  //   //             "Access-Control-Allow-Methods": "POST",
  //   //             "Access-Control-Allow-Headers":
  //   //               "Content-Type, Authorization, X-Requested-With",
  //   //           },
  //   //           data: {
  //   //             externalId: uid,
  //   //           },
  //   //         })
  //   //         .then((response) => {
  //   //             console.log("phyllo user info:", response);
  //   //             if (response.data !== "NOT FOUND") {
  //   //               dispatch(updatePhylloUser(response.data));
  //   //             } else {
  //   //               console.log("need to create user");
  //   //             /* ***********************************  CREATE USER ********************************* */
  //   //               // axios({
  //   //               //   method: "POST",
  //   //               //   url: config.API_SERVER + "/api/phyllo/createUser",
  //   //               //   headers: {
  //   //               //     "Content-Type": "application/json",
  //   //               //     "Access-Control-Allow-Origin": "*",
  //   //               //     "Access-Control-Allow-Methods": "POST",
  //   //               //     "Access-Control-Allow-Headers":
  //   //               //       "Content-Type, Authorization, X-Requested-With",
  //   //               //   },
  //   //               //   data: {
  //   //               //     userName: uname,
  //   //               //     externalId: uid,
  //   //               //   },
  //   //               // })
  //   //               //   .then((response) => {
  //   //               //     if (response.status===200){
  //   //               //       console.log("phyllo created user:", response);
  //   //               //       dispatch(updatePhylloUser(response.data))
  //   //               //     } else {
  //   //               //       console.log("COULD NOT CREATE USER")
  //   //               //     }
  //   //               //   })
  //   //               //   .catch((error) => {
  //   //               //     console.log("could not create user", error);
  //   //               //   });
  //   //             /* ***********************************  CREATE USER ********************************* */
  //   //             }
  //   //           })
  //   //           .catch((error) => {
  //   //             console.log(error);
  //   //           });
  //   // }
  //   // if (config.API_SERVER){
  //   //   checkPhylloUser(user.sub);
  //   // }
  // },[dispatch,config,user.id]);

  // const uploadVideo = (auth) =>{
  //   axios({
  //     method: "POST",
  //     url: config.API_SERVER + "/api/youtube/upload",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "POST",
  //       "Access-Control-Allow-Headers":
  //         "Content-Type, Authorization, X-Requested-With",
  //     },
  //     data: {
  //       pkg:{
  //         auth: auth,
  //         container:"assets",
  //         name:"auth0%7C59b0525c4fe7b66e81c21a13/1d708f95-d385-4e29-a00e-41a57e18ad4c.mp4"
  //      }
  //     },
  //   })
  //   .then((response) => {
  //       console.log("video uploaded!!", response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const renderYoutubeActions = (youtubeTokens) =>{
    if (youtubeTokens){
      return (
<>
        {/* <Button size='sm' onClick={()=>uploadVideo(youtubeTokens)}>upload video to youtube</Button> */}
        {/* token: {youtubeTokens} */}
        </>
              )
    } 
  }
  return (

    <Flex flexDirection={"column"} p='4'>
    
      <Grid
        h="200px"
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(4, 1fr)"
        gap={8}
      >
        <GridItem colSpan={2} rowSpan={1} justifyItems={"stretch"}>
          <Card p="4">
            <CardBody style={{ display: "flex" }} flexGrow={1}>
              <Flex wrap={"wrap"} direction={"row"} gap={"4"}>
                <Flex grow={1} width={"100%"}>
                  <div className="profileDetails">
                    <div
                      className="profileAvatar"
                      style={{ backgroundImage: "url(" + user.imageUrl + ")" }}
                    ></div>
                    <div className="profileinfo">
                      <div className="profileName">{user.fullName}</div>
                      <div className="profileHandle">{user.primaryEmailAddress.emailAddress}</div>
                      <div style={{ paddingTop: "20px", paddingRight: "20px" }}>
                        {/* <PhylloAccounts /> */}
                        <SocialManager />
                        {socials && socials.length>0 && renderYoutubeActions(socials.find((element)=>element.service==='youtube'))}
                      </div>
                      {/* <TagManager /> */}
                    </div>
                  </div>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default Home;
