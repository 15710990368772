import React from 'react';

const FlowPanel = () => {
  const onDragStart = (event, nodeType) => {
    console.log("drag start",event)
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('application/reactflow/w',event.target.clientWidth);
    event.dataTransfer.setData('application/reactflow/h',event.target.clientHeight);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.nodeW = event.target.clientWidth;
    event.dataTransfer.nodeH = event.target.clientHeight;
    
  };

  return (
    <aside>
      <div className="description">You can drag these nodes to the pane on the right.</div>
      <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'input')} draggable>
        Input Node
      </div>
      <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
        Default Node
      </div>
      <div className="dndnode output" onDragStart={(event) => onDragStart(event, 'output')} draggable>
        Output Node
      </div>
    </aside>
  );
};
export default FlowPanel;
