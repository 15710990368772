
import React from "react";
import Flow from "../components/Automation/Flow";

export function Automation() {
    return (
        <Flow />
    )
}

export default Automation;
