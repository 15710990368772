import { createSlice } from '@reduxjs/toolkit'
// import { _ } from 'lodash'
// import axios from "axios";

const initialState = {
  spaces: [],
  activeSpace: null  
}

export const spacesSlice = createSlice({
  name: 'spaceMgr',
  initialState,
  reducers: {

    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    setActiveSpace:(state,action)=>{
       // console.log("setting active space");
        state.activeSpace=action.payload;
    },
    setSpaces:(state,action)=>{
       // console.log("spaces udpated")
        state.spaces = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setActiveSpace, setSpaces} = spacesSlice.actions

export default spacesSlice.reducer