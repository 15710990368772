import React, { useState } from "react";
// import { NavLink as RouterNavLink } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import { Button} from "reactstrap";
import { SettingsIcon } from "@chakra-ui/icons";
// import { useAuth0 } from "@auth0/auth0-react";
import { MenuDivider, Heading } from "@chakra-ui/react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  // MenuItem,
  // Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  Avatar,
  AvatarGroup
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { setActiveSpace, setSpaces } from "../features/spaces/spacesSlice";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import generateGuid from "../utils/guid.js";
import { useClerk } from "@clerk/clerk-react";
import axios from "axios";
import ClerkHeader from "./Clerk/ClerkHeader.js";
function Auth(props) {
  // const { colorMode } = useColorMode()
  // const [isOpen, setIsOpen] = useState(false);
  const config = useSelector((state) => state.config.keys);
  const { onOpen } = useDisclosure();
  const dispatch = useDispatch();

  const spaceMgr = useSelector((state) => state.spaceMgr);
  const [editSpace, setEditSpace] = useState(null);
  // const [modalOpen, setModalOpen] = useState(false)
  const { expanded } = props;
  const { user } = useClerk();
  // const toggle = () => setIsOpen(!isOpen);

  // const logoutWithRedirect = () =>
  // {
  //   console.log("logging out")
  //   logout({
  //       logoutParams: {
  //         returnTo: window.location.origin,
  //       }
  //   })
  // };

  const setSpaceToEdit = (spaceId) => {
    setEditSpace(spaceMgr.spaces.find((item) => item.spaceId === spaceId));
  };

  const removeSpace = async (spaceId) => {
    //grab creator details

    await axios({
      method: "delete",
      url: config.API_SERVER + "/api/spaces/",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Authorization, X-Requested-With",
      },
      data: {
        pkg: {
          spaceId: spaceId,
          owner: user.id,
        },
      },
    })
      .then((response) => {
        console.log("SPACE removed :", response.data);
        dispatch(setSpaces(response.data.spaces));
        if (response.data.spaces.length > 0) {
          dispatch(setActiveSpace(response.data.spaces[0]));
        } else {
          dispatch(setActiveSpace(null));
        }
        setEditSpace(null);
        // grabSpaces();
      })
      .catch((error) => console.error(error));
  };

  const updateSpace = async () => {
    //grab creator details
    const spaceDetails = {
      spaceName: editSpace.spaceName,
      spaceId: editSpace.spaceId || generateGuid(),
      owner: user.id,
    };
    console.log(spaceDetails);

    await axios({
      method: "patch",
      url: config.API_SERVER + "/api/spaces/",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, PATCH, GET, OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Authorization, X-Requested-With",
      },
      data: { pkg: spaceDetails },
    })
      .then((response) => {
        console.log("SPACE updated :", response.data);
        dispatch(setActiveSpace(response.data.updatedSpace));
        dispatch(setSpaces(response.data.spaces));
        setEditSpace(null);
      })
      .catch((error) => console.error(error));
  };


  React.useEffect(() => {
    const grabSpaces = async (server, user) => {

      //grab creator details
      await axios({
        method: "post",
        url: server + "/api/spaces/list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Authorization, X-Requested-With",
        },
        data: {
          pkg: {
            userId: user,
          },
        },
      })
        .then((response) => {
          //console.log("SPACES LIST returned for APP:",response.data);
          dispatch(setSpaces(response.data));
          if (response.data.length > 0) {
            //   console.log("setting modal closed")
            // setModalOpen(false)
            dispatch(setActiveSpace(response.data[0]));
          } else {
            //    console.log("setting modal open")
            setEditSpace(generateGuid());
          }
          // dispatch(updateSocials(response.data))
        })
        .catch((error) => console.error(error));
    };

    if (user && config.API_SERVER){

      //console.log("API SERVER:",config.API_SERVER, user.sub)
      grabSpaces(config.API_SERVER, user.id);
  }

  }, [config.API_SERVER, user, dispatch]);

  return (
    <Flex
      gap="4"
      flexDirection={"row"}
      alignItems={"center"}
      className={!expanded ? "sidebarLink" : ""}
      px={"2px"}
    >
      <Modal
        size="xl"
        isOpen={editSpace !== null}
        closeOnOverlayClick={false}
        onOpen={onOpen}
        onClose={() => console.log("modal closed")}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customize your space</ModalHeader>
          <ModalCloseButton onClick={() => setEditSpace(null)} />
          <ModalBody>
            <FormControl>
              <FormLabel>Name your creator space</FormLabel>
              <Input
                placeholder="Creator space name"
                defaultValue={editSpace ? editSpace.spaceName : ""}
                onKeyUp={(e) => {
                  setEditSpace({
                    ...editSpace,
                    spaceName: e.target.value,
                  });
                  //                setSpaceName(e.target.value);
                  // updateEvent();
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex gap='2'><Button size={'sm'} onClick={() => updateSpace()}>Save space</Button>
            <Button size={'sm'} 
              variant={"outline"}
              onClick={() => removeSpace(spaceMgr.activeSpace.spaceId)}
            >
              Delete space
            </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>


                <Menu  >
                    <MenuButton 
                      aria-label='profile'
                      variant='subtle'
                     >
                      <Flex gap='4' style={{padding:'12px 10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
{spaceMgr.activeSpace && (
                      <Heading size='xs' textTransform={'uppercase'} m='0'>{spaceMgr.activeSpace.spaceName}</Heading>
)}
                   </Flex>
                                
                     </MenuButton>
                <MenuList style={{borderRadius:'16px',boxShadow:'rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(25, 28, 33, 0.2) 0px 15px 35px -5px'}}>
                <Flex px='4' py='2' gap="4" flexDirection={'column'}>
                    <Heading size="xs" m='0' fontSize='13px' fontWeight='100'>Members</Heading>
                    <Flex>
                      <AvatarGroup size={"sm"} marginBottom="0" max={3}>
                      <Avatar name="Dan Abrahmov" />
                      <Avatar name="Kola Tioluwani" />
                      <Avatar name="Kent Dodds" />
                      <Avatar name="+" icon="Add" />
                    </AvatarGroup>
                    <Avatar name="+" size={"sm"} icon="Add" />
                    </Flex>
                  </Flex>
                  <MenuDivider />
                  <Flex  px='4' alignItems={'center'} justifyContent={'space-between'}>
                  <Heading size="xs" m='0'  fontSize='13px' fontWeight='100'>Workspaces</Heading>
                    <Flex size='xs' style={{cursor:"pointer"}} variant={'outline'} onClick={()=>setEditSpace({spaceId:generateGuid(),owner:user.sub,spaceName:""})}>+</Flex>
                  </Flex>
                  {spaceMgr.spaces.map((item,idx)=>{
                    return (<Flex key={idx} px='4' my='4' onClick={()=>dispatch(setActiveSpace(item))}>
                      <Flex  fontSize='13px' fontWeight='100' direction="row" flexGrow={1} alignItems='center' justifyContent='space-between'>
                        <Flex gap='4' alignItems='center' >
                          <Flex alignItems='center' >{(spaceMgr.activeSpace.spaceName===item.spaceName) ? <div>*</div>:<div>-</div>} {item.spaceName}</Flex>
                        </Flex>
                        <SettingsIcon onClick={()=>{ 
                          setSpaceToEdit(item.spaceId)

                          }} size='xs'/>
                      </Flex>
                      </Flex>)
                    }
                  )}
                  {/* 
                  <Flex px='4'>
                  <a target="Billing" href="https://billing.stripe.com/p/login/test_5kAdSk3Dd8CB27mcMM" alt="customer billing">Billing Portal</a>
                  </Flex> */}
                </MenuList>
              </Menu>
              <ClerkHeader></ClerkHeader>

    </Flex>
  );
}

export default Auth;
