// ./src/App.tsx

import React, { useState,useEffect,useRef } from 'react';
// import Path from 'path';
import { Dropzone,FileMosaic } from '@files-ui/react';
import uploadFileToBlob, {listFilesInContainer, isStorageConfigured } from './azureBlob';//deleteBlobInContainer
import { Button, VStack,Progress } from '@chakra-ui/react';


const storageConfigured = isStorageConfigured();

const FileUpload = (props) => {
  const videoRef = useRef(null)
const {sasKey,userContext,fileContext,projectContext,onMediaUpload,onFileToUpload} = props;
  // all blobs in container
  const [blobList, setBlobList] = useState([]);
  const [progress, setProgress] = useState(0);
  // const [audioBlob, setAudioBlob] = useState(null);
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState(null);
  const [files,setFiles] = useState([])
  // UI/form management
  // const [uploading, setUploading] = useState(false);
//   const [inputKey, setInputKey] = useState(Math.random().toString(36));

  // const onFileChange = (event) => {
  //   // capture file into state
  //   setFileSelected(event.target.files[0]);
  // };

  const updateFiles = (incomingFiles) => {
    onFileToUpload(incomingFiles[0]);
    //do something with the files
     console.log("incoming files", incomingFiles[0]);
    setFiles(incomingFiles);
    setFileSelected(incomingFiles[0])
    //even your own upload implementation
  };

  const onFileUpload = async () => {
    // prepare UI
    // setUploading(true);
    // setProgress(5);
    console.log(fileContext);
    // *** UPLOAD TO AZURE STORAGE ***
    const azureFileName= projectContext.projectId + "/" + fileContext.fileId;
    console.log(azureFileName);
    console.log(sasKey);
    const blobsInContainer = await uploadFileToBlob(fileSelected,sasKey,userContext.id,azureFileName,setProgress);

    // prepare UI for results
    // setBlobList(blobsInContainer.filter((item)=>item.indexOf(fileContext) >=0));
    
    
    
    onMediaUpload(blobsInContainer[0])



    // const updatedBlobsInContainer = await listFilesInContainer(sasKey,userContext,fileContext)
    // //console.log(updatedBlobsInContainer.filter((item)=>item.indexOf(fileContext)>=0));
    // setBlobList(updatedBlobsInContainer.filter((item)=>item.indexOf(fileContext)>=0));

    // reset state/form
    setFileSelected(null);


    // setUploading(false);
    // setInputKey(Math.random().toString(36));
  };
/* ***************************************************** */
/*     DELETE BLOB                                       */
/* ***************************************************** */


// const deleteBlob = async () =>{
  //   console.log(blobList)
  //   // console.log("about to delete:",file)
  //   if (blobList.length>0){
  //     console.log( await deleteBlobInContainer(sasKey, blobList[0]));
  //   }
  //  // const updatedBlobsInContainer = await listFilesInContainer(sasKey,userContext,fileContext)
  //   // console.log(updatedBlobsInContainer.filter((item)=>item.indexOf(fileContext)>=0));
  //   //setBlobList(updatedBlobsInContainer.filter((item)=>item.indexOf(fileContext)>=0));
  // }

  // display form
  const DisplayForm = () => (
    <VStack gap='4'  style={{width:"320px"}} alignItems='flex-start'>
                      <Dropzone
                      style={{width:"100%"}}
                      onChange={updateFiles}
                      value={files}
                      maxFiles={1}
                      placeholder={'Drop your video here'}
                      accept="video/mp4"
                    >
                      {files.map((file) => (
                        <FileMosaic
                          key={file.id}
                          {...file}
                        //   onDelete={removeFile}
                          info
                          preview
                        />
                      ))}
                    </Dropzone>
      {/* <Input variant='outline' colorScheme='blue'  type="file" onChange={updateFiles}  /> */}
      {/* key={inputKey || ''} */}
      <Progress value={progress} style={{width:'100%'}}/>
      <Button variant='outline' colorScheme='blue' type="submit" onClick={onFileUpload}>Upload to kreeko</Button>
      
    </VStack>
  );

  // display file name and image
  const DisplayImagesFromContainer = () => (
    <div  style={{overflow:"hidden" }}>
         {blobList.map((item,idx) => {
          return (
              <div  style={{width:"100%",minWidth:"320px",position:"relative"}} key={idx} >
                {/* {item} */}
                {/* {Path.basename(item)} */}
                {/* <br /> */}
                {item.indexOf(".mp4") >=0 && (
                  <video ref={videoRef} src={item} controls={true}/>
                )}
                  {/* <div style={{position:'absolute',top:'10px',right:'10px'}}>
                          <Button borderRadius='50%' onClick={()=>{deleteBlob()}}>X</Button>
                  </div> */}
                
                  {/* <div style={{position:'absolute',top:'10px',left:'10px'}}>
                          <Button borderRadius='10px' onClick={()=>{
                            const currentT = videoRef.current.currentTime/60;
                            const currentM = Math.trunc(currentT)
                            const currentS = Math.trunc((currentT % 1) * 60)
                            console.log(currentM + "min/ " +currentS + "seconds");
                            }}>timestamp</Button>
                            <Button
                            onClick={()=>{
                              videoRef.current.currentTime = (8*60 + 24)
                            }}>Jump to 8min 24 sec</Button>
                  </div> */}
                
                {/* {(item.indexOf(".jpg") >=0 || item.indexOf(".png")>=0) && (
                  <img src={item} alt={item} height="200" />
                )} */}
              </div>
          );
        })}

    </div>
  );

  useEffect(()=>{
  // console.log(sasKey, userContext)
  async function getBlobs(){
    const blobsInContainer = await listFilesInContainer(sasKey,userContext,fileContext)
    //console.log(blobsInContainer);
   // console.log(blobsInContainer.filter((item)=>item.indexOf(fileContext.projectId)>=0));
    setBlobList(blobsInContainer.filter((item)=>item.indexOf(fileContext.fileId)>=0));
  }
  if (sasKey) {getBlobs()};

},[sasKey,userContext,fileContext])

  return (
    <VStack gap={4}>
      {/* <div>{JSON.stringify(fileContext)}</div> 
     <div>  saskey:{sasKey}<br/>
      user: {userContext.sub}<br/></div> */}
      {/* {fileContext} */}
      {storageConfigured && blobList.length > 0 && DisplayImagesFromContainer()}
      {storageConfigured && blobList.length === 0 && DisplayForm()}
      {/* 
      {storageConfigured && !uploading && DisplayForm()}
      {storageConfigured && uploading && <div>Uploading</div>}
      {!storageConfigured && <div>Storage is not configured.</div>} */}
    </VStack>
  );
};

export default FileUpload;