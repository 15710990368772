import React, {useEffect,useState} from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { setPageFeed } from './theFeedSlice'
import { Input,Button,Flex,Card,VStack,Box, Tabs, TabList, TabPanels, Tab, TabPanel  } from "@chakra-ui/react";
import axios from "axios"
import moment from 'moment/moment';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { AISummary } from '../../components/AISummary';
// import { getConfig } from '../../config';
import Image from '../../components/Image';
import "./TheFeed.css";
import { useSelector } from 'react-redux';
import AIAssistant from '../../components/AIAssistant';

export function TheFeed() {

  // const theFeed = useSelector((state) => state.theFeed)
  // const dispatch = useDispatch()

  const config = useSelector((state)=>state.config.keys);

  const [query,setQuery] = useState("");
  const [selectedFeedItem, setSelectedFeedItem]=useState(null)
  const [pageFeed, setPageFeed] = useState([]);
  const [isOpen,setIsOpen] = useState(false);
  // const [facts, setFacts] = useState(null);
  const [news,setNews] = useState([]);

  const toggleDrawer=(feedItem)=>{
    console.log(feedItem);
    setSelectedFeedItem(feedItem);
    setIsOpen((prevState => !prevState))
  }


  const doSearch = () =>{
    // setFacts(null);
     //console.log("query:" + query)
    axios.get(config.API_SERVER + "/api/search/" + encodeURIComponent(query),{
      headers:{
        "Access-Control-Allow-Origin":"*"
      }
    })
      .then(response => {
   //     console.log(response.data.result);
        // if (response.data.result.searchResults.facts){
        //   setFacts(response.data.result.searchResults.facts);
        // }
        setPageFeed(response.data.result.feed)
        // setCreators(response.data.result)
      })
      .catch(error => console.error(error));
  }


  useEffect(() => {
    //console.log(config.API_SERVER);
    axios.post(config.API_SERVER + "/api/videos/feed",
    {
      headers:{
        "Access-Control-Allow-Origin":"*"
      }
    })
      .then(response => {
      //  console.log(response.data)
        setNews(response.data)
      })
      .catch(error => console.error(error));
  },[config.API_SERVER]);

  const calcRecency = (vidData)=>{
    if (!vidData.stats) return 400;
    const dateToday = new Date(Date.now())
    const datePosted = new Date(vidData.youtubeData.snippet.publishedAt);
    return Math.floor((dateToday.getTime() - datePosted.getTime()) / 1000 / 60 / 60 / 24 / 7)
  }
  const calcRating = (vidData) =>{
    if (!vidData.stats) return 400;
    try {
      //console.log("Rating " + vidData.videoId + ": ", vidData.stats)
      //  youtubeData.snippet.publishedAt

      const recency = calcRecency(vidData) // Math.floor((dateToday.getTime() - datePosted.getTime()) / 1000 / 60 / 60 / 24) //num of weeks since posted
  
      //  ChannelData.statistics.
      //         "viewCount": "16273004",
      //         "subscriberCount": "225000",
      //         "videoCount": "343",
  
      // const channelViews = parseInt(vidData.ChannelData.statistics.viewCount) //total number of 
      // const channelSubscribers = parseInt(vidData.ChannelData.statistics.subscriberCount);
      // const channelVideoCount = parseInt(vidData.ChannelData.statistics.videoCount);
  
      //  Video Statistics
      //  youtubeData.stats.
      //     "viewCount": "235095",
      //     "likeCount": "4894",
      //     "favoriteCount": "0",
      //     "commentCount": "133",
      const vidViewCount = parseInt(vidData.stats.viewCount);
  
      // const vidLikeCount = parseInt(vidData.youtubeData.stats.likeCount);
      // const vidFavCount = parseInt(vidData.youtubeData.stats.favoriteCount);
      // const vidCommentCount = parseInt(vidData.youtubeData.stats.commentCount);
  
      // more subscribers (negative weighting)
      // individual views / subs / days
  
      let viewPerDayAvg=0;
      let rating=0;
      let targetViewPerDay=0;
      if (0 <= recency <= 7){
        //first week
        targetViewPerDay = 500
        viewPerDayAvg = vidViewCount/recency
        rating = viewPerDayAvg/targetViewPerDay
      }
      if (7 <= recency <= 30){
        //first month
        targetViewPerDay = 250
        viewPerDayAvg = vidViewCount/recency
        rating = viewPerDayAvg/targetViewPerDay
      }
      if (30 <= recency <= 365){
        //first month
        targetViewPerDay = 100
        viewPerDayAvg = vidViewCount/recency
        rating = viewPerDayAvg/targetViewPerDay
      }
      return Math.floor(rating);
    } catch(error){
      console.log("rating error: ",error)
    }
  }

  return (
      <>
      <div className="content">

      <Tabs size='sm' variant='soft-rounded' colorScheme='brand'>
        <TabList>
          <Tab>AI assistant</Tab>
          <Tab>Feed</Tab>
          <Tab>Saved</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AIAssistant />
          </TabPanel>
          <TabPanel>
          <Drawer style={{zIndex:2000}}
                      open={isOpen}
                      onClose={toggleDrawer}
                      direction='right'
                      className='bla bla bla'
                      size='50vw'
                  >
                      <AISummary feedItem={selectedFeedItem} />
                  </Drawer>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", paddingTop:"20px"}}>
                <div style={{display:"flex",flexDirection:"row", gap:"10px",paddingBottom:"20px"}}>
                  <Input size='sm' backgroundColor={'white'} style={{width:"240px"}} type="text" name="searchBox"  id="sb" placeholder="Enter a topic to search for" onChange={(e)=>{setQuery(e.currentTarget.value)}} value={query} />
                  <Button size='sm' type="submit" onClick={(e)=>{doSearch()}} >Search</Button>
                  </div>
                  {/* {facts && (
                    <div className="factsBlurb">
                        {facts.value[0].description}
                    </div>
                  )} */}
                  {/* {(pageFeed.length===0) &&(
                    <div className="content-2">Enter a search term to find some recent info about the topic</div>
                  )} */}
                  <div>Latest</div>
                  {news && news.length>0 && (
                    <Flex flexWrap={'wrap'} alignItems={'flex-start'}                 gap={4}>
                      {news.map((item,idx)=>{
                        return(
                        <Box key={idx} style={{maxWidth:"250px", cursor:'pointer'}}>
                        <Card style={{overflow:"hidden",height:"375px"}} alignItems={'flex-start'} onClick={()=>{window.open("https://www.youtube.com/watch?v=" + item.videoId)}}>
                          <VStack gap={0} >
                          <Box pb='4'><img src={item.youtubeData.snippet.thumbnails.medium.url} alt={item.youtubeData.snippet.title} style={{width:"100%"}} /></Box>
                          <Box px='4' alignItems={'flex-start'} style={{color:"#aaa",fontSize:'12px'}}>{item.youtubeData.snippet.channelTitle}</Box>
                          <Box px='4'>{item.youtubeData.snippet.title}</Box>
                          <Box px='4'>Weeks since published: {calcRecency(item)}</Box>
                          <Box px='4'>views: {((item.stats)? item.stats.viewCount : 'n/a')}</Box>
                          <Box px='4'>Rating: {calcRating(item)}</Box>

                        </VStack></Card>
                        </Box>)
                      })}
                    </Flex>
                  )}
                  {pageFeed  && (
                    <ResponsiveMasonry style={{width:"90%"}}
                    columnsCountBreakPoints={{300:1,600:2,900:3,1200:4,1400:5}}>
                        <Masonry gutter='10px'>
                        {pageFeed && pageFeed.map((feedItem,index)=>{
                        
                        //console.log(feedItem);
                        if (feedItem.thumbnailUrl){
                            return(
                              <div onClick={()=>toggleDrawer(feedItem)} style={{width:"100%",cursor:"pointer",borderRadius:"12px",overflow:"hidden",backgroundColor:"#fff",boxShadow:"2px 2px 5px #33333366"}}  key={index} >
                                <Image src={feedItem.thumbnailUrl} style={{width:"100%"}} alt={feedItem.name}/>
                                <div className="content-2" style={{padding:"10px 10px 0"}}>{feedItem.name}</div>
                                <div className="caption" style={{padding:"0 10px"}}>{moment(feedItem.datePublished).fromNow()}</div>
                              </div>
                            )
                          } else {
                            return (<div key={index} style={{width:"1px", height:"1px"}}></div>)
                          }
                        })}
                        </Masonry> 
                    </ResponsiveMasonry>
                )}
              </div>
          </TabPanel>
          <TabPanel>
            Saved stuff will go here :)
          </TabPanel>
        </TabPanels>
      </Tabs>

      
        </div>
      </>
  )
}