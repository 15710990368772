import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  feedItems:[],
  value:0
}

export const theFeedSlice = createSlice({
  name: 'theFeed',
  initialState,
  reducers: {
    setPageFeed: (state,action) =>{
      state.feedItems = action;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setPageFeed } = theFeedSlice.actions

export default theFeedSlice.reducer