import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";
// import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Home from "./views/Home";
import Landing from "./views/Landing";
// import Process from "./views/Process";
import Feed from "./views/Feed";
// import Footer from "./components/Footer/Footer";
// import MyCalendar from "./views/Calendar";
// import Community from "./views/Community";
// import Competition from "./views/Competition";
import Bio from "./views/Bio";

import axios from "axios";
import { updateConfig } from "./features/config/configSlice";
import { updateAuth } from "./features/auth/authSlice";
import { useDispatch} from "react-redux";
import Sidebar from "./components/Sidebar/SideBar";

import Privacy from "./views/Privacy";
import PageHeader from "./components/PageHeader";
import Teams from "./views/Teams";
import Analytics from "./views/Analytics";
import Automation from "./views/Automation";
import OAuth from "./views/OAuth/OAuth";

import ProjectsList from "./views/ProjectsList";
import ProjectDetails from "./views/ProjectDetails";

import StripePlans from "./components/Stripe/StripePlans";

import { useUser, SignedIn, SignedOut } from "@clerk/clerk-react";


initFontAwesome();

const App = () => {
  const { user, isLoaded  } = useUser();

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState("/");

  const dispatch = useDispatch();



  const toggleSideBar = () => {
    // alert("toggling");
    setSideBarOpen(!sideBarOpen);
  };
  const isLive = true;
  

  useEffect(() => {
    let apiServer = isLive
    ? "https://kreekoapi.azurewebsites.net"
    : "http://localhost:7071";

    if (isLoaded){
      // const getSAS = async () => {
      //   await axios({
      //     method: "post",
      //     url: apiServer + "/api/azure",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //     data: {
      //       pkg: {},
      //     },
      //   })
      //     .then((response) => {
      //       dispatch(updateSAS(response.data.sasKey));
      //      // console.log("sasKey",response.data.sasKey)
      //     })
      //     .catch((error) => console.log(error));
      // };

      //GRAB CONFIG DETAILS
      const grabConfig = async () => {
        await axios({
          method: "post",
          url: apiServer + "/api/config",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Authorization, X-Requested-With",
          },
          data: {},
        })
          .then((response) => {
            //console.log("config keys:", response.data.keys);
            dispatch(updateConfig(response.data.keys));
          })
          .catch((error) => console.error(error));
      };
      grabConfig();
      // getSAS();
      dispatch(updateAuth(user))
    }
  },[isLive,dispatch,user,isLoaded]);

  if (!isLoaded) {
    console.log("loading");
    return <Loading />;
  } else {
    return (
    <>
      <SignedIn>
        <div id="app" className="d-flex flex-column h-100">
        <Router history={history}>

          <PageHeader
            currentSection={currentSection}
            isAuthenticated={true}
            toggleSideBar={toggleSideBar}
            setCurrentSection={setCurrentSection}
            sideBarOpen={sideBarOpen}
          />
          {/* <ClerkHeader></ClerkHeader> */}
            <div className="root">
              <Sidebar
                sideBarOpen={sideBarOpen}
                toggleSideBar={toggleSideBar}
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
              />
              <div className={sideBarOpen ? "pageBody" : "pageBody collapsed"}>
                {/* <Flex
                  bgColor={colorMode === "light" ? "purple.50" : "#212130"}
                  px="4"
                  py="2"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <div style={{ fontSize: "12px", fontWeight: "100" }}>
                    {activeSpace && <>{activeSpace.spaceName}</>}
                    {!activeSpace && <>loading...</>}
                  </div>
 
                </Flex> */}
     
                <Switch>
                  <Route path="/plans" component={StripePlans} />
                  <Route path="/oauth" component={OAuth} />
                  <Route path="/dashboard" component={Home} />
                  {/* <Route path="/calendar" component={MyCalendar} /> */}
                  {/* <Route path="/community" component={Community} /> */}
                  {/* <Route path="/competition" component={Competition} /> */}
                  <Route path="/feed" component={Feed} />
                  <Route path="/analytics" component={Analytics} />
                  <Route path="/teams" component={Teams} />
                  <Route
                    path="/projects/:projectId"
                    component={ProjectDetails}
                  />
                  <Route path="/projects" component={ProjectsList} />

                  <Route path="/automation" component={Automation} />
                  {/* <Route path="/fr" component={FirstRun} /> */}
                  <Route path="/bio" component={Bio} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/*" component={Home} />
                </Switch>
    
                </div>
            </div>
          </Router>
        </div>
      </SignedIn>
      <SignedOut>
        <Router history={history}>
          {/* <ClerkHeader></ClerkHeader> */}
          <PageHeader
            currentSection={currentSection}
            isAuthenticated={false}
            toggleSideBar={toggleSideBar}
            sideBarOpen={sideBarOpen}
            setCurrentSection={setCurrentSection}

          />
          <Switch>
            <Route path="/privacy" component={Privacy} />
            <Route path="/*" component={Landing} />
          </Switch>
        </Router>
      </SignedOut>
    </>
    );
  }
}


export default App;
