
// import { useRadioGroup } from "@chakra-ui/react"
import { Flex } from "@chakra-ui/react"
import Auth from "./Auth"
// 1. Create a component that consumes the `useRadio` hook
import kreekoLogo from '../assets/img/kreekoLogo.png'
import kreekoType from '../assets/img/kreeko_type.svg'
import { MdMenu } from "react-icons/md";
import { useColorMode,Button } from "@chakra-ui/react";
import { NavLink as RouterLink } from "react-router-dom";

// import { Button } from "@chakra-ui/react";
// import { NavLink as RouterLink } from "react-router-dom";
import { MdLightMode,MdDarkMode,MdOutlineSettings,MdNotificationsNone ,MdClose  } from "react-icons/md";
function PageHeader(props){
const {isAuthenticated,sideBarOpen, toggleSideBar,currentSection,setCurrentSection} = props;
const { colorMode, toggleColorMode } = useColorMode()
const getPageTitle = (path) =>{
    switch(path){
        case "/dashboard":
            return "dashboard"
        case "/teams":
            return "Collaboration team";
        case "/feed":
            return "Ideas";
        case "/projects":
            return "Posts";
        case "/bio":
            return "Landing Page";
        case "/analytics":
            return "Analytics"
        default:
            return "";
        }
}
  return( 

  <Flex flexDirection={'row'} bgColor={(colorMode==='light'? '#fff' : '#1B1B27')} alignItems='center' justifyContent={'space-between'} px='2'>
        <Flex flexDirection={'row'}  alignItems='center' gap='4' p='6px 12px' style={{cursor:"pointer"}}  >
            {isAuthenticated && !sideBarOpen && (
                <MdMenu onClick={toggleSideBar} style={{height:'24px',width:'24px'}} />
            )}
            {isAuthenticated && sideBarOpen && (
                <MdClose  onClick={toggleSideBar} style={{height:'24px',width:'24px'}} />
            )}
            <Button variant={'transparent'} as={RouterLink} to="/" onClick={()=>setCurrentSection("/")} style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                
            {/* <a href="/" alt="home"> */}
            <img src={kreekoLogo } style={{height:'48px'}} alt="logo"/>
            <img src={kreekoType } style={{height:'24px'}}  alt="kreeko" />
            <Flex alignItems={'center'}   style={{transform:"translateY(4px)",padding:'12px 10px',fontSize:"12px",fontWeight:'600'}}>{getPageTitle(currentSection).toUpperCase()}</Flex>
            </Button>
            
        </Flex>
        <Flex flexDirection={'row'}  alignItems='center'>
            {/* {!isAuthenticated && (
                <Flex gap='4' direct="row" alignItems={'center'}  style={{cursor:'pointer', padding:'12px 10px'}}>
                    <Flex alignItems={'center'}   style={{cursor:'pointer', padding:'12px 10px',fontWeight:'600'}}>Features</Flex>
                    <Flex alignItems={'center'}   style={{cursor:'pointer', padding:'12px 10px',fontWeight:'600'}}>Pricing</Flex>
                </Flex>
            )} */}
            {isAuthenticated && (
            <Flex gap='1' direct="row"  style={{cursor:'pointer', padding:'12px 10px'}} >
                <MdNotificationsNone color={colorMode === 'light' ? '#333':'#eee'}/>
            </Flex>
            )}
            {isAuthenticated && (
            <Flex gap='1' direct="row"  style={{cursor:'pointer', padding:'12px 10px'}} >
                <MdOutlineSettings color={colorMode === 'light' ? '#333':'#eee'}/>
            </Flex>
            )}
            <Flex gap='1' direct="row"  style={{cursor:'pointer', padding:'12px 10px'}} onClick={()=>toggleColorMode()}>
                 {colorMode === 'light' ? <MdLightMode color={'#333'}/> : <MdDarkMode color={'#eee'}/>}
            </Flex>
            <Auth expanded={true}/>
    </Flex>
  </Flex>
 

  
  )
}
export default PageHeader

