// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient} from '@azure/storage-blob';

// const sasToken = process.env.storagesastoken || "?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-02-11T03:45:48Z&st=2024-02-10T19:45:48Z&spr=https&sig=wZowRgI5z6TP4jR3L3y4aVRzF1hyQyNKPUhnqEu67rI%3D"; // Fill string with your SAS token
const containerName = `assets`;
const storageAccountName = process.env.storageresourcename || "kreekoassets"; // Fill string with your Storage resource name

// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !((!storageAccountName )); // || !sasToken
};

// return list of blobs in container to display
const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
};

export const deleteBlobInContainer = async (sasKey,file) => {
  console.log("delete blob in container function")
  if (file.indexOf("?">0)){
    const blobName = decodeURIComponent(file.split("?")[0].replace("https://kreekoassets.blob.core.windows.net/assets/",""))
    console.log("deleting blob:" + blobName)
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasKey}`
    );
    // get Container - full public read access
    const containerClient = blobService.getContainerClient(containerName);

  // include: Delete the base blob and all of its snapshots.
    // only: Delete only the blob's snapshots and not the blob itself.
    const options = { deleteSnapshots: 'include' }; // or 'only'
    
    // Create blob client from container client
    const blockBlobClient = await containerClient.getBlockBlobClient(blobName);
    await blockBlobClient.deleteIfExists(options);
    
    console.log(`Deleted blob ${blobName}`);
      return true
  } else { 
    console.log("file name unexpected format:", file)
      return false
  }
}



const createBlobInContainer = async (containerClient, file,user,event,onProgress) => {
  var fileExt = file.name.split('.').pop()

  // // set mimetype as determined from browser with file upload control
  // const options = { blobHTTPHeaders: { blobContentType: file.type } };
  
  console.log("trying to upload ",file);

  const blobName= encodeURI(user) + "/" +event + "." + fileExt.toLowerCase();
  const blockBlobClient = containerClient.getBlockBlobClient(blobName) //file.name); //encodeURI(user) + "/" + 

//  const file = document.getElementById("file").files[0];
  const uploadResponse = await blockBlobClient.uploadData(file.file, {
    blockSize: 100 * 1024 * 1024, // 4MB block size
    concurrency: 20, // 20 concurrency
    onProgress: (ev) => {
      onProgress(Math.floor((ev.loadedBytes/file.size) *100))
      console.log(Math.floor((ev.loadedBytes/file.size) *100))
    },
    maxSingleShotSize: 100 * 1024 * 1024,
    blobHTTPHeaders: { blobContentType: file.type } // set mimetype
    
  });
  console.log("upload response: ",uploadResponse)
  // var reader = new FileReader();
  // // const fileByteArray = []
  // reader.onloadstart = function(evt){
  //   console.log("reader started");
  // }
  // reader.onerror = function(evt){
  //   console.log("reader error", evt);
  // }
  // reader.onprogress = function (evt){
  //   console.log("upload progress " , evt)
  // }
  // reader.onloadend = async function(evt) {
  //    console.log( evt.target.result)
  //      // create blobClient for container

  //     const blobName= encodeURI(user) + "/" +event + "." + fileExt.toLowerCase();
  //     const blobClient = containerClient.getBlockBlobClient(blobName) //file.name); //encodeURI(user) + "/" + 

  //   if (evt.target.readyState === FileReader.DONE) {
  //     const arrayBuffer = evt.target.result;
  //     // const array = new Uint8Array(arrayBuffer);

  //     // for (const a of array) {
  //     //   fileByteArray.push(a);
  //     // }

  //     // console.log("file byte array",fileByteArray);
      
  //     // upload file
  //     await blobClient.uploadData(arrayBuffer); //fileByteArray
  //     await blobClient.setMetadata({UserName : user});
  //     console.log("upload finished:", blobName);
  //    }
  // }
 
  //  reader.readAsArrayBuffer(file.file);
  

};
export const listFilesInContainer = async(sasKey,user,event)=>{
  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasKey}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${encodeURI(blob.name)}?${sasKey}`
    );
  }
  // console.log(returnedBlobUrls);
  return returnedBlobUrls;

  // // get list of blobs in container
  // return getBlobsInContainer(containerClient);
}

const uploadFileToBlob = async (file,sasKey,user,event,onProgress) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasKey}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  await createBlobInContainer(containerClient, file, user,event,onProgress);

  // get list of blobs in container
  return getBlobsInContainer(containerClient);
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;