import {Card,CardBody,Flex} from "@chakra-ui/react";
import React from "react";

export function Analytics() {
    return (
      <Flex flexDirection={'column'}>
          {/* <Heading size="xs" textTransform="uppercase">
            Analytics
          </Heading> */}
          <Card>
            <CardBody>
            Analytics page
              </CardBody>
            </Card>
        </Flex>
    )
}

export default Analytics;
