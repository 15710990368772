// import {Card,CardHeader,CardTitle,CardBody,Row,Col} from "react-bootstrap";
import React from "react";
import { Flex,Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { SocialIcon } from "react-social-icons";
export function BioSocials() {
    const socials = useSelector((state)=> state.profile.socials);

    return (
        <Flex className="BioSocials" direction="column" gap={5} style={{width:"100%"}} p='20px' alignItems={'flex-start'}>
          <Flex shrink={1}>
            <Heading  size="xs" textTransform="uppercase">Socials</Heading>
          </Flex>
          <Flex shrink={1} gap='5'>
            {socials.map((item,idx)=>{
                return (
                    <SocialIcon key={idx} network={item.service} />
                )
            })}
          </Flex>

        </Flex>
    )
}

export default BioSocials;
