import React,{ useEffect } from "react";
import "./Sidebar.css"
// import {AiOutlineTeam } from "react-icons/ai";
// import { LuLayoutDashboard,LuBarChartBig } from "react-icons/lu";
import {LuHome, LuFolder, LuLightbulb,LuContact } from "react-icons/lu";
import {Button,Flex} from '@chakra-ui/react';
import { NavLink as RouterLink } from "react-router-dom";

import { useColorMode } from "@chakra-ui/react";

function Sidebar(props){
  // const {isAuthenticated } = useAuth0();
  const { colorMode } = useColorMode()
  const {sideBarOpen,  currentSection, setCurrentSection} = props;

  useEffect(() => {
  }, [])

  const getColor = (mode, active) =>{
    if (mode==="light" && !active) return '#333'
    if (mode==="light" && active) return '#FFF'
    if (mode==="dark"  && active) return '#eee'
    if (mode==="dark" && !active) return '#eee'
  }

  return (

        <nav  className={sideBarOpen? 'expanded ' + colorMode : 'collapsed ' + colorMode }>
            {/* <div className="sidebar-top"  >
              <Link as={RouterLink} to='/' className="logo__wrapper" onClick={()=>setCurrentSection("/")}>
                <img src={logo} alt="Logo" className="sidebarlogo"/>
                <h1 className={sideBarOpen? 'show' : 'hide'} ><img style={{height:"32px"}} src={kreekoType} alt="kreeko" /></h1>
              </Link>
            </div> */}
            <div className="sidebar-links">
                <ul>
                <li className={currentSection==="/" ? '' : ''} >
                    <Button variant={currentSection==="/"?'navSelected':'nav'}   as={RouterLink}   to="/"  title="Home"  onClick={()=>setCurrentSection("/")}>
                      <LuHome color={getColor(colorMode,(currentSection==='/'))}/>
                      {sideBarOpen && (<span className="link " style={{color:getColor(colorMode,(currentSection==='/'))}}>Home</span>)}

                    </Button>
                  </li>
                  {/* 
                  <li className={currentSection==="/teams" ? '' : ''} >
                    <Button  variant={currentSection==="/teams"?'navSelected':'nav'}   as={RouterLink}   to="/teams"  title="team"  onClick={()=>setCurrentSection("/teams")}>
                      <AiOutlineTeam color={getColor(colorMode,(currentSection==='/teams'))}/>
                      {sideBarOpen && (<span className="link " style={{color:getColor(colorMode,(currentSection==='/teams'))}}>Team</span>)}
                    </Button>
                  </li> */}
               <li className={currentSection==="/feed" ? '' : ''} >
                    <Button  variant={currentSection==="/feed"?'navSelected':'nav'}   as={RouterLink}  to="/feed"   onClick={()=>setCurrentSection("/feed")}>
                      <Flex direction={'row'} alignItems={'center'} p='0'>
                        <LuLightbulb color={getColor(colorMode,(currentSection==='/feed'))}/>
                        <span className={sideBarOpen?"link ":"link collapsed"} style={{color:getColor(colorMode,(currentSection==='/feed'))}}>Inspiration</span>
                      </Flex>
                                          </Button>
                  </li>
                  <li className={currentSection==="/projects" ? '' : ''} >
                    <Button variant={currentSection==="/projects"?'navSelected':'nav'}  as={RouterLink} to="/projects" onClick={()=>setCurrentSection("/projects")}>
                    <Flex direction={'row'} alignItems={'center'} p='0'>
                      <LuFolder color={getColor(colorMode,(currentSection==='/projects'))}/>
                      <span className={sideBarOpen?"link ":"link collapsed"} style={{color:getColor(colorMode,(currentSection==='/projects'))}}>Projects</span>
</Flex>
                    </Button>
                  </li>
                  <li className={currentSection==="/bio" ? '' : ''} >
                    <Button variant={currentSection==="/bio"?'navSelected':'nav'}  as={RouterLink} to="/bio" onClick={()=>setCurrentSection("/bio")}>
                    <Flex direction={'row'} alignItems={'center'} p='0'>
                                    <LuContact  color={getColor(colorMode,(currentSection==='/bio'))}/>
                      <span className={sideBarOpen?"link ":"link collapsed"} style={{color:getColor(colorMode,(currentSection==='/bio'))}}>Bio</span>
                    </Flex>
                    </Button>
                  </li>
                  {/* <li className={currentSection==="/analytics" ? '' : ''} >
                    <Button variant={currentSection==="/analytics"?'navSelected':'nav'}  as={RouterLink} to="/analytics" onClick={()=>setCurrentSection("/analytics")}>
                      <LuBarChartBig   color={getColor(colorMode,(currentSection==='/analytics'))}/>
                      {sideBarOpen && (<span className="link " style={{color:getColor(colorMode,(currentSection==='/analytics'))}}>Analytics</span>)}

                    </Button>
                  </li> */}
                </ul>
              </div>
             

       
          </nav>






    )
}
export default Sidebar;