import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";

export default function ClerkHeader() {
  return (
    <>
      <SignedOut>
        <SignInButton />
      </SignedOut>
      <SignedIn>
        <UserButton />
      </SignedIn>
    </>
  )
}