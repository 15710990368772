// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'
import { mode } from "@chakra-ui/theme-tools";
import { Button } from './theme/ButtonTheme';
// 2. Add your color mode config
// const config = {
//   initialColorMode: 'light',
//   useSystemColorMode: false,
// }

// 3. extend the theme
const theme = extendTheme({ 
    components: {
      Button,
    },
    config:{
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    breakpoints : {
      base: "0px",
      sm: "320px",
      md: "768px",
      lg: "960px",
      xl: "1200px",
      "2xl": "1536px",
    },
    fonts: {
      body: 'Karla, Times New Roman, sans-serif',
      heading: 'Karla, Times New Roman, sans-serif',
      mono: 'Karla, Times New Roman, sans-serif', 
    },
    styles:{
      global:(props) =>({
        body:{
          bg: mode('brand.50', '#12121A')(props),
          color: mode('blue.900', '#F5F4FF')(props)
        },
        button:{
          br: '16px'
        }
      })
    },
    colors: {
      brand: {
        "50": "#E7E6FF",
        "100": "#BCB8FE",
        "200": "#918BFE",
        "300": "#655EFD",
        "400": "#3A30FD",
        "500": "#0F03FC",
        "600": "#0C02CA",
        "700": "#090297",
        "800": "#060165",
        "900": "#030132"
      },
      blue: {
        "50": "#EDE9FC",
        "100": "#CEC1F6",
        "200": "#AE99F0",
        "300": "#8E71EA",
        "400": "#6F48E4",
        "500": "#4F20DF",
        "600": "#3F1AB2",
        "700": "#2F1386",
        "800": "#200D59",
        "900": "#10062D"
      }
    }
})

export default theme
