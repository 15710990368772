import React from "react";
import { Flex } from "@chakra-ui/react";
import Hero from "./Landing/Hero";
// import Features from "./Landing/Features";
// import Pricing from "./Landing/Pricing";


function Landing(props) {

  React.useEffect(() => {

  });
  return (
    <Flex flexDirection={'column'} p='4' gap='4'>
      <Hero/>
      {/* <Features />
      <Pricing /> */}
    </Flex>
 );
}

export default Landing;
