import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { Auth0Provider } from "@auth0/auth0-react";
// import history from "./utils/history";
// import { getConfig } from "./config";
import { store} from './store';
import { Provider} from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react'
import { ColorModeScript } from '@chakra-ui/react'
import theme from "./theme";
import { ClerkProvider } from '@clerk/clerk-react'
import ErrorBoundary from "./components/Error/ErrorBoundary";

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

const CLERK_KEY = 'pk_test_b3B0aW11bS1sb2N1c3QtODMuY2xlcmsuYWNjb3VudHMuZGV2JA'
// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
// const config = getConfig();

// const providerConfig = {
//   domain: 'fizimo.auth0.com',
//   clientId: 'BgLN9GpnRyNfD4W7bo7B5pIWFRQRP0zr',
//   onRedirectCallback,
//   authorizationParams: {
//     redirect_uri: window.location.origin,
//     audience:"https://www.fizimo.com/api"
//     // ...(config.audience ? { audience: config.audience } : null),
//   },
// };

const root = createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      {/* <Auth0Provider
        {...providerConfig}
      > */}
        <ClerkProvider publishableKey={CLERK_KEY}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <ErrorBoundary>
          <App />
          </ErrorBoundary>
        </ClerkProvider>
      {/* </Auth0Provider> */}
    </Provider>
  </ChakraProvider>
,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
