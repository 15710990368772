import { Wrap,WrapItem } from "@chakra-ui/react";
import React,{useEffect} from "react";
import { SocialIcon } from "react-social-icons";
// import _ from "lodash";
import { useSelector,useDispatch } from "react-redux";
// import { getConfig } from "../../config";
import { encode as base64_encode} from 'base-64';
// import { useAuth0 } from "@auth0/auth0-react";
import { useClerk } from "@clerk/clerk-react";
import { useLocation } from "react-router-dom";
import { updateSocials } from "../../features/profile/profileSlice";
import axios from "axios";
function SocialManager(props){
    // const auth_config = getConfig();
const dispatch = useDispatch();

  const socials = useSelector((state)=> state.profile.socials);
    const auth_config = useSelector((state)=>state.config.keys);
    const spaceMgr = useSelector((state)=>state.spaceMgr);
    const { user} = useClerk();
    const encodedUser = base64_encode(user.id)
    const location=useLocation();
    //console.log("api server:" + auth_config.API_SERVER)

    // const dispatch = useDispatch()
    // const pinterestAuth=(userId)=>{
    //     console.log("state encoded=" + encodedUser)
    //     const stateObj = {
    //         userId: user.sub,
    //         redirect_uri: auth_config.APP_ORIGIN + "/fr"
    //     }
    
    //     const encodedState = base64_encode(JSON.stringify(stateObj));
    //     console.log("trying to log in");
    //     // Google's OAuth 2.0 endpoint for requesting an access token
    //     var oauth2Endpoint = 'https://www.pinterest.com/oauth/?';
    
    //         // Parameters to pass to OAuth 2.0 endpoint.
    //     var params = {
    //     'client_id': auth_config.PINTEREST_CLIENTID,
    //     'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/pinterest"),
    //     'response_type': 'code',
    //     'scope': 'liteprofile emailaddress w_member_social',
    //     'code_challeng':'challenge',
    //     'challenge_method':'plain',
    //     'state': encodedState
    //     //show_dialog:true
    //   };
    
          
    //     // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    //     var form = document.createElement('form');
    //     form.setAttribute('method', 'GET'); // Send as a GET request.
    //     form.setAttribute('action', oauth2Endpoint);
    
    //   // Add form parameters as hidden input values.
    //     for (var p in params) {
    //     var input = document.createElement('input');
    //     input.setAttribute('type', 'hidden');
    //     input.setAttribute('name', p);
    //     input.setAttribute('value', params[p]);
    //     form.appendChild(input);
    //     }
    
    //     // Add form to page and submit it to open the OAuth 2.0 endpoint.
    //     document.body.appendChild(form);
    //     form.submit();
    //    }

//    const linkedinAuth=(userId)=>{
//     console.log("state encoded=" + encodedUser)
//     const stateObj = {
//         userId: user.sub,
//         redirect_uri: auth_config.APP_ORIGIN + "/fr"
//     }

//     const encodedState = base64_encode(JSON.stringify(stateObj));
//     console.log("trying to log in");
//     // Google's OAuth 2.0 endpoint for requesting an access token
//     var oauth2Endpoint = 'https://www.linkedin.com/oauth/v2/authorization?';

//         // Parameters to pass to OAuth 2.0 endpoint.
//     var params = {
//     'client_id': auth_config.LINKEDIN_CLIENTID,
//     'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/linkedin"),
//     'response_type': 'code',
//     'scope': 'liteprofile emailaddress w_member_social',
//     'code_challeng':'challenge',
//     'challenge_method':'plain',
//     'state': encodedState
//     //show_dialog:true
//   };

      
//     // Create <form> element to submit parameters to OAuth 2.0 endpoint.
//     var form = document.createElement('form');
//     form.setAttribute('method', 'GET'); // Send as a GET request.
//     form.setAttribute('action', oauth2Endpoint);

//   // Add form parameters as hidden input values.
//     for (var p in params) {
//     var input = document.createElement('input');
//     input.setAttribute('type', 'hidden');
//     input.setAttribute('name', p);
//     input.setAttribute('value', params[p]);
//     form.appendChild(input);
//     }

//     // Add form to page and submit it to open the OAuth 2.0 endpoint.
//     document.body.appendChild(form);
//     form.submit();
//    }


    // const twitterAuth=(userId)=>{
    //     console.log("state encoded=" + encodedUser)
    //     const stateObj = {
    //         userId: user.sub,
    //         redirect_uri: auth_config.APP_ORIGIN + "/fr"
    //     }

    //     const encodedState = base64_encode(JSON.stringify(stateObj));
    //     console.log("trying to log in");
    //     // Google's OAuth 2.0 endpoint for requesting an access token
    //     var oauth2Endpoint = 'https://twitter.com/i/oauth2/authorize?';
  
    //         // Parameters to pass to OAuth 2.0 endpoint.
    //     var params = {
    //     'client_id': auth_config.TWITTER_CLIENTID,
    //     'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/twitter"),
    //     'response_type': 'code',
    //     'scope': 'tweet.write follows.read tweet.read offline.access',
    //     'code_challeng':'challenge',
    //     'challenge_method':'plain',
    //     'state': encodedState
    //     //show_dialog:true
    //   };

          
    //     // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    //     var form = document.createElement('form');
    //     form.setAttribute('method', 'GET'); // Send as a GET request.
    //     form.setAttribute('action', oauth2Endpoint);
  
    //   // Add form parameters as hidden input values.
    //     for (var p in params) {
    //     var input = document.createElement('input');
    //     input.setAttribute('type', 'hidden');
    //     input.setAttribute('name', p);
    //     input.setAttribute('value', params[p]);
    //     form.appendChild(input);
    //     }
    
    //     // Add form to page and submit it to open the OAuth 2.0 endpoint.
    //     document.body.appendChild(form);
    //     form.submit();
    // }
    const spotifyAuth=(userId)=>{
        console.log("state encoded=" + encodedUser)
        const stateObj = {
            userId: user.id,
            spaceId: spaceMgr.activeSpace._id,
            redirect_uri: auth_config.WEBSITE + location.pathname
        }

        const encodedState = base64_encode(JSON.stringify(stateObj));
        console.log("trying to log in");
        // Google's OAuth 2.0 endpoint for requesting an access token
        var oauth2Endpoint = 'https://accounts.spotify.com/authorize/';
  
            // Parameters to pass to OAuth 2.0 endpoint.
        var params = {
        'client_id': auth_config.SPOTIFY_CLIENTID,
        'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/spotify"),
        'response_type': 'code',
        'access_type':'offline',
        'auth_type':'rerequest',
        'scope': 'user-read-playback-position',
        'state': encodedState
        //show_dialog:true
      };

          
        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        var form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);
  
      // Add form parameters as hidden input values.
        for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
        }
    
        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }

    const instagramAuth=(userId)=>{
        //https://developers.facebook.com/docs/instagram/business-login-for-instagram#example-response
        console.log("state encoded=" + encodedUser)
        const stateObj = {
            userId: user.id,
            spaceId: spaceMgr.activeSpace._id,
            redirect_uri: auth_config.WEBSITE + location.pathname
        }

        const encodedState = base64_encode(JSON.stringify(stateObj));
        console.log("trying to log in");
        // Google's OAuth 2.0 endpoint for requesting an access token
        var oauth2Endpoint = 'https://www.facebook.com/dialog/oauth?';
  
            // Parameters to pass to OAuth 2.0 endpoint.
        var params = {
        'client_id': auth_config.INSTAGRAM_CLIENTID,
        'redirect_uri': encodeURI( auth_config.WEBSITE + "/oauth/instagram?#test=0"),
        'display':'page',
        'response_type': 'token',
        'extras':'{"setup":{"channel":"IG_API_ONBOARDING"}}',
        'auth_type':'rerequest',
        'scope': ['instagram_basic','instagram_graph_user_media','instagram_graph_user_profile','instagram_content_publish','instagram_manage_comments','instagram_manage_insights','pages_show_list','pages_read_engagement'].join(','),
        'state': encodedState
      };

          
        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        var form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);
  
      // Add form parameters as hidden input values.
        for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
        }
    
        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }

    const facebookAuth=(userId)=>{
        console.log("state encoded=" + encodedUser)
        const stateObj = {
            userId: user.id,
            spaceId: spaceMgr.activeSpace._id,
            redirect_uri: auth_config.WEBSITE + location.pathname        }

        const encodedState = base64_encode(JSON.stringify(stateObj));
        console.log("trying to log in");
        // Google's OAuth 2.0 endpoint for requesting an access token
        var oauth2Endpoint = 'https://www.facebook.com/v4.0/dialog/oauth?';
  
            // Parameters to pass to OAuth 2.0 endpoint.
        var params = {
        'client_id': auth_config.FACEBOOK_CLIENTID,
        'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/facebook"),
        'response_type': 'code',
        'access_type':'offline',
        'auth_type':'rerequest',
        'scope': ['email', 'user_friends'].join(','),
        'state': encodedState,
        'display':'popup'
      };

          
        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        var form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);
  
      // Add form parameters as hidden input values.
        for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
        }
    
        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }

    const tiktokAuth=(userId)=>{
        console.log("state encoded=" + encodedUser)
        const stateObj = {
            userId: user.id,
            spaceId: spaceMgr.activeSpace._id,
            redirect_uri: auth_config.WEBSITE + location.pathname
        }

        const encodedState = base64_encode(JSON.stringify(stateObj));
        console.log("trying to log in");
        // Google's OAuth 2.0 endpoint for requesting an access token
        var oauth2Endpoint = 'https://www.tiktok.com/v2/auth/authorize/';
  
        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        var form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);
  
        // Parameters to pass to OAuth 2.0 endpoint.
        var params = {'client_key': auth_config.TIKTOK_CLIENTKEY,
                      'scope': '',
                      'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/tiktok"),
                      'state': encodedState,
                      'response_type': 'code',
                    };
  
        // Add form parameters as hidden input values.
        for (var p in params) {
          var input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', p);
          input.setAttribute('value', params[p]);
          form.appendChild(input);
        }
  
        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();

    }
    const checkSocials = (socialName)=>{
        if (socials.findIndex((obj)=> obj.service ===socialName) >= 0) {
            return true
        } else {
            return false;
        }
    }

    const googleOAuth=()=>{
        console.log("state encoded=" + encodedUser)
        const stateObj = {
            userId: user.id,
            spaceId: spaceMgr.activeSpace._id,
            redirect_uri: auth_config.WEBSITE + location.pathname
        }

        const encodedState = base64_encode(JSON.stringify(stateObj));
        console.log("trying to log in");
        // Google's OAuth 2.0 endpoint for requesting an access token
        var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
  
        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        var form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);
  
        // Parameters to pass to OAuth 2.0 endpoint.
        var params = {'client_id': auth_config.YOUTUBE_OAUTH_CLIENT_ID,//"630348987848-n7k7r769meqf4qkv22tc7u2ev2q68u7m.apps.googleusercontent.com",
                      'redirect_uri': encodeURI( auth_config.API_SERVER + "/api/oauth/youtube"),
                      'response_type': 'code',
                      'access_type':'offline',
                      'scope': 'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/yt-analytics-monetary.readonly https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.upload',
                      'state': encodedState,
                      'include_granted_scopes': 'true'
                    };
  
        // Add form parameters as hidden input values.
        for (var p in params) {
          var input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', p);
          input.setAttribute('value', params[p]);
          form.appendChild(input);
        }
  
        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
  
      }
      
      useEffect(()=>{
        const grabSocialAccounts = async (server, user) => {
                    //grab configured social accounts
                    await axios({
                      method: "post",
                      url: server + "/api/socials/list",
                      headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                        "Access-Control-Allow-Headers":
                          "Content-Type, Authorization, X-Requested-With",
                      },
                      data: { pkg: {
                        userId: user,
                      } },
                    })
                      .then((response) => {
                        console.log("social list returned for APP:", response.data);
                        dispatch(updateSocials(response.data));
                      })
                      .catch((error) => console.error(error));
                  };
                if (auth_config.API_SERVER && user.id) {
                    grabSocialAccounts(auth_config.API_SERVER,user.id);

                }
            },[dispatch,user.id,auth_config.API_SERVER])
    
    return(
        (
<>
            <Wrap>
            
                <WrapItem>
                    <SocialIcon style={(checkSocials('youtube'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}}  onClick={()=>{googleOAuth()}} network="youtube"></SocialIcon>
                </WrapItem>
                <WrapItem>
                    <SocialIcon style={(checkSocials('tiktok'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{tiktokAuth()}} network="tiktok"></SocialIcon>
                </WrapItem>
                <WrapItem>
                    <SocialIcon style={(checkSocials('facebook'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{facebookAuth()}} network="facebook"></SocialIcon>
                </WrapItem>
                <WrapItem>
                    <SocialIcon style={(checkSocials('instagram'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{instagramAuth()}} network="instagram"></SocialIcon>
                </WrapItem>
                <WrapItem>
                    <SocialIcon style={(checkSocials('spotify'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{spotifyAuth()}} network="spotify"></SocialIcon>
                </WrapItem>
                {/* <WrapItem>
                    <SocialIcon style={(checkSocials('linkedin'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{linkedinAuth()}} network="linkedin"></SocialIcon>
                </WrapItem>
                <WrapItem>
                    <SocialIcon style={(checkSocials('twitter'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{twitterAuth()}} network="x"></SocialIcon>
                </WrapItem> */}
                {/* <WrapItem>
                    <SocialIcon style={(checkSocials('threads'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(0)',opacity:'.5',cursor:'pointer'}} onClick={()=>{}} network="threads"></SocialIcon>
                </WrapItem> */}
                {/* <WrapItem>
                    <SocialIcon style={(checkSocials('pinterest'))? {width:'32px',height:'32px',cursor:'pointer'} : {width:'32px',height:'32px',filter:'grayscale(1)',opacity:'.5',cursor:'pointer'}} onClick={()=>{pinterestAuth()}} network="pinterest"></SocialIcon>
                </WrapItem> */}
            </Wrap>
</>

        
        
        )
    )
} 

export default SocialManager;