import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";

import {
  Flex,
  // Card,
  // WrapItem,
  // Wrap,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
// import {MdCalendarMonth,MdCreateNewFolder  } from "react-icons/md";
// import {getConfig} from '../config.js';
//  import { ChevronDownIcon } from "@chakra-ui/icons";

import axios from "axios";
//   import { StaticCalendar } from "../components/StaticCalendar";
// import { Col, Row } from "react-bootstrap";
// import KanbanBoard from "../components/Kanban/KanbanBoard.js";
// import EventDetails from "../components/EventDetails.js";
import generateGuid from "../utils/guid.js";
import FileUpload from "../components/Azure/fileUpload";
// import { active } from "d3";
// import DataGrid from "../components/DataGrid/DataGrid.js";
// import { SocialIcon } from "react-social-icons";

// import AzureStorage from "../components/Azure/AzureStorage.js";
// import { FaUsers } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import {deleteBlobInContainer} from '../components/Azure/azureBlob.js';
import { updateSAS } from "../features/config/configSlice.js";

// import { updateSAS } from "../features/config/configSlice.js";

function ProjectsList() {
  const { onOpen } = useDisclosure();
   const history = useHistory();
   const dispatch = useDispatch();
  // const auth_config = getConfig();
  const config = useSelector((state) => state.config.keys);
  // const sasKey = useSelector((state)=> state.config.sasKey);
  // const {onClose} = useDisclosure();
  const userInfo = useSelector((state) => state.auth.user);
  const spaceMgr = useSelector((state) => state.spaceMgr);
  // const [scheduledEvents,setScheduledEvents] = useState([]);
  // const [unscheduledEvents, setUnscheduledEvents] = useState([]);
  //   const [activeDate,setActiveDate] = useState(new Date());
  // const [detailDate, setDetailDate] = useState(new Date());

  // const [tasks,setTasks] = useState([]);
  //  const [dates] = useState([new Date("2-2-2024"), new Date("2-14-2024"),  new Date("2-25-2024")]);
  const [files, setFiles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [editProject, setEditProject] = useState(null);
  // const [uploadFileDetails, setUploadFileDetails] = useState(null);

  const [sasKey, setSasKey] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);

const deleteFile = async(fileContext) =>{

      console.log("about to delete",fileContext)
      console.log("sasKey",sasKey)
      //  console.log("sasKey",response.data.sasKey)
      deleteBlobInContainer(sasKey,fileContext.mediaUri).then((response)=>{
        console.log("delete resonse:" + response);
        if (response){
          console.log("delete file from db on success")

          axios({
            method: "delete",
            url: config.API_SERVER + "/api/files",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: {
              pkg: {
                userId:userInfo.id,
                projectId: activeProject.projectId,
                workspaceId:spaceMgr.activeSpace.spaceId,
                fileId:fileContext.fileId
              },
            },
          }).then((response) => {
            console.log(response.data);
          });
        }
        else {
          console.log("file not deleted from azure")
        }
      })
}
  const updateProject = async () => {
    // You can await here
    console.log(editProject);
    await axios({
      method: "post",
      url: config.API_SERVER + "/api/projects/",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        pkg: {
          userId: userInfo.id,
          workspaceId: spaceMgr.activeSpace.spaceId,
          projectId: editProject.id,
          title: editProject.title,
          timestamp: Date.now(),
        },
      },
    })
      .then((response) => {
        setActiveProject(editProject);
        setEditProject(null);
        let newProjectList = [...projects];
        newProjectList.push(response.data.projectDetails);
        setProjects(newProjectList);
        //
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  };

  const removeProject = async (projectId) => {
    await axios({
      method: "delete",
      url: config.API_SERVER + "/api/projects/",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Authorization, X-Requested-With",
      },
      data: {
        pkg: {
          projectId: projectId,
          workspaceId: spaceMgr.activeSpace.spaceId,
          userId: userInfo.id,
        },
      },
    })
      .then((response) => {
        console.log("project removed :", response.data);
        setProjects(response.data.projectList);
      })
      .catch((error) => console.error(error));
  };

  const getFilesForActiveProject = async (user, project, workspace) => {
    // You can await here
    await axios({
      method: "post",
      url: config.API_SERVER + "/api/files/list",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        pkg: {
          userId: user,
          projectId: project,
          workspaceId: workspace,
        },
      },
    })
      .then((response) => {
        setFiles(response.data.files);
        //console.log(response.data);
      })
      .catch((error) => console.log(error));
    // ...
  };

  // -------------------------------------------------------------
  const [fileContext, setFileContext] = useState(null);

 

  const fileUploaded = (newMedia) => {
    console.log("file to upload", fileToUpload);
    console.log("file uploaded/saving to event: " + newMedia);
    const updatedFile = {
      ...fileContext,
      projectId: activeProject.projectId,
      mediaUri: newMedia,
    };
    setFileToUpload(null);
    setFileContext(updatedFile);
    console.log("UPDATED PROJECT", updatedFile);

    axios({
      method: "post",
      url: config.API_SERVER + "/api/files",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        pkg: updatedFile,
      },
    }).then((response) => {
      console.log(response.data);
    });
  };
  

  // ------------------------------------------------------------
useEffect(()=>{
  const getSAS = async () => {
    await axios({
      method: "post",
      url: config.API_SERVER + "/api/azure",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        pkg: {},
      },
    })
      .then((response) => {
        setSasKey(response.data.sasKey)
        dispatch(updateSAS(response.data.sasKey))
        //console.log("sasKey",response.data.sasKey)
      })
      .catch((error) => console.log(error));
  };

  getSAS();
},[config.API_SERVER,dispatch])

  useEffect(() => {
    async function fetchEvents() {
      // You can await here
      await axios({
        method: "post",
        url: config.API_SERVER + "/api/projects/list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          pkg: {
            userId: userInfo.id,
            test: "blah",
            workspaceId: spaceMgr.activeSpace.spaceId,
          },
        },
      })
        .then((response) => {
          setProjects(response.data.projects);
        //  console.log("active project",activeProject)
          if (!activeProject){
            setActiveProject(response.data.projects[0])
          }
          //console.log(response.data);
        })
        .catch((error) => console.log(error));
      // ...
    }
    // console.log("api server:"  + config.API_SERVER)
    fetchEvents();
  }, [config.API_SERVER, spaceMgr.activeSpace.spaceId, userInfo.id,activeProject]);
  return (
    <Flex flexDirection={"row"}>
      <Modal
        size="xl"
        isOpen={fileContext !== null}
        closeOnOverlayClick={false}
        onOpen={() => {}}
        onClose={() => console.log("modal closed")}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File upload</ModalHeader>
          <ModalCloseButton onClick={() => setFileContext(null)} />
          <ModalBody>
            <FormControl>
              <FormLabel>SAS:{sasKey}</FormLabel>
              <FormLabel>Enter file title</FormLabel>
              <Input
                placeholder="File title"
                defaultValue={fileContext ? fileContext.title : ""}
                onKeyUp={(e) => {
                  setFileContext({
                    ...fileContext,
                    title: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>File to upload</FormLabel>
              <FileUpload
                onFileToUpload={setFileToUpload}
                onMediaUpload={fileUploaded}
                projectContext={activeProject}
                fileContext={fileContext}
                userContext={userInfo}
                sasKey={sasKey}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="xl"
        isOpen={editProject !== null}
        closeOnOverlayClick={false}
        onOpen={onOpen}
        onClose={() => console.log("modal closed")}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Project details</ModalHeader>
          <ModalCloseButton onClick={() => setEditProject(null)} />
          <ModalBody>
            <FormControl>
              <FormLabel>Enter project title</FormLabel>
              <Input
                placeholder="Project title"
                defaultValue={editProject ? editProject.title : ""}
                onKeyUp={(e) => {
                  setEditProject({
                    ...editProject,
                    title: e.target.value,
                  });
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex gap="2">
              <Button size={"sm"} onClick={() => updateProject()}>
                Save project
              </Button>
              {/* <Button size={'sm'} 
              variant={"outline"}
              onClick={() => removeProject(editProject.id)}
            >
              Delete space
            </Button> */}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
      backgroundColor={'#ffffff66'}
        flexDirection="column"
        justifyContent={"flex-start"}
        width={"200px"}
        height={'calc(100vh - 52px)'}
      >
        <Heading size="sm" p="4">
          Project List
        </Heading>
        <Flex flexDirection={"column"}>
          {projects &&
            projects.map((project, idx) => {
              return (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  backgroundColor={
                    activeProject &&
                    activeProject.projectId === project.projectId
                      ? "#cccccc99"
                      : "transparent"
                  }
                  px="4"
                  key={idx}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setActiveProject(project);
                    getFilesForActiveProject(
                      userInfo.id,
                      project.projectId,
                      spaceMgr.activeSpace.spaceId
                    );
                    // history.push("./projects/" + project.projectId)
                   // console.log(project.projectId);
                  }}
                >
                  {project.title}
                  <Button
                    size="sm"
                    variant={"transparent"}
                    onClick={() => removeProject(project.projectId)}
                  >
                    X
                  </Button>
                </Flex>
              );
            })}
        </Flex>
        <Button
          size="sm"
          justifyContent={"flex-start"}
          variant={"transparent"}
          onClick={() => {
            setEditProject({
              id: generateGuid(),
            });
          }}
        >
          New Project
        </Button>
      </Flex>
      <Flex
        p="4"
        flexShrink={0}
        flexGrow={1}
        flexDirection={"column"}
      >
        <Heading size="sm">Items</Heading>
        <Flex>
          {files &&
            files.map((file, idx) => {
              return <Card  key={idx} style={{cursor:'pointer'}} p="4">
                  <Flex>
                    <div  onClick={()=>{history.push("./projects/" + file.fileId)}}>{file.title}</div>
                    <Button
                        size="sm"
                        variant={"transparent"}
                        onClick={() => deleteFile(file)}
                      >
                        X
                      </Button>
                  </Flex>
                    </Card>;
          })}
          {files && files.length === 0 ? "no files" : ""}

        </Flex>
        <Button
            size="sm"
            justifyContent={"flex-start"}
            variant={"transparent"}
            onClick={() => {
              setFileContext({
                fileId: generateGuid(),
                projectId: (activeProject)?activeProject.projectId:null,
                userId: userInfo.id,
                workspaceId: spaceMgr.activeSpace.spaceId
                  ? spaceMgr.activeSpace.spaceId
                  : 0,
                title: "New video",
                timestamp: Date.now(),
                mediaUri: "",
                comments: [],
              });
            }}
          >
            Add file
          </Button>
      </Flex>
      {/*  */}
    </Flex>
  );
}

export default ProjectsList;
