import React  from "react";
import { Flex } from "@chakra-ui/react";
// import {Container,Card,CardHeader,CardTitle,CardBody,Row,Col} from "react-bootstrap";
// import Hero from "../components/Hero";
// import Content from "../components/Content";
// import {Counter} from "../features/counter/Counter";
// import {useSelector} from 'react-redux';
// import AdminLayout from "../layouts/Admin.js";
// import React, { useState, useEffect } from "react";

// import { useGetPokemonByNameQuery} from "../services/pokemon";
// import  {useGetFeedByNameQuery}  from "../services/feedSearch";
import {TheFeed} from "../features/feed/TheFeed";
// import SiteNavBar from "../components/SiteNavBar";
// import ProjectBoard from "../features/board/ProjectBoard";

function Feed(props) {

    // const {data,error, isLoading} = useGetPokemonByNameQuery('bulbasaur');
    // const {data, error, isLoading } = useGetFeedByNameQuery('bulbasaur');
    // const count = useSelector((state) => state.counter.value)
    // //  const dispatch = useDispatch()


    return (
      // <AdminLayout />
      <Flex  flexDirection={'column'} p='4'>
          {/* <Heading size="xs" textTransform="uppercase">
            Ideation
          </Heading> */}
      <div style={{position:"relative"}}>
        <div style={{display:"flex"}}>
              {/* <div style={{width:"200px"}}>
              <ProjectBoard filters={["Ideation"]}/>

              </div> */}
              <div style={{paddingLeft:"10px",flexGrow:1}}>
                <TheFeed style={{width:"100%"}}/>
              </div>
            </div>
        {/* <div className="header-2">About</div>
        <div className="content-1">The digital Asset Platform</div>
        <div className="content-2">It combines a permissioned distribution list</div> */}
      </div>
      </Flex>
    )
}

export default Feed;
