import React, { useState, useEffect,useRef } from "react";
// import ProjectBoard from "../features/board/ProjectBoard";
import { useSelector } from "react-redux";
// import { RadioGroup } from "chakra-radio-group";
// import { GoogleApis } from "googleapis";
// import { SocialIcon } from "react-social-icons";
// import { SingleDatepicker } from "chakra-dayzed-datepicker";

import axios from "axios";

import {BiSolidComment} from "react-icons/bi";
import Image from "../components/Image.js"
import {
  Card,
  Flex,
  CardBody,
  Heading,
  Textarea,
  IconButton,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
  // FormHelperText,
} from "@chakra-ui/react";
import moment from "moment";
// import {getConfig} from "../config.js"
// import { Text } from "@chakra-ui/react";
// import CustomRadio from "./CustomRadio.js";

// import TagControl from "./TagControl.js";
// import FileUpload from "../components/Azure/fileUpload.js";
// import TagControl from "../components/TagControl.js";
import { useParams,Link } from "react-router-dom";
import generateGuid from "../utils/guid.js"

export function ProjectDetails(props) {
    let { projectId } = useParams();
    let  commentBoxRef = useRef();
    const videoRef = useRef(null)
  // const config= getConfig();
  const config = useSelector((state) => state.config.keys);
//   const socials = useSelector((state) => state.profile.socials)
  const spaceMgr = useSelector((state)=> state.spaceMgr);
  const userInfo = useSelector((state) => state.auth.user);
//   const gapi = window.gapi;


const [fileContext, setFileContext]= useState(null)

const [commentInput,setCommentInput]=useState("add comment...");
// const [jumpToTime,setJumpToTime]=useState(0)

const [highlightComment, setHighlightComment]=useState(null);
  const [sasKey, setSasKey] = useState("");
//   const [token,setToken] = useState("");

// const [fileToUpload,setFileToUpload]= useState(null);
const [isScrubbing, setIsScrubbing] = useState(false);

  // const fileUploaded = (newMedia) => {
  //   console.log("file to upload",fileToUpload)
  //   console.log("file uploaded/saving to event: " + newMedia);
  //   const updatedProject = {
  //       ...fileContext,
  //       mediaUri:newMedia
  //   }
  //   setFileToUpload(null);
  //   setFileContext(updatedProject)
  //   console.log("UPDATED PROJECT",updatedProject)


  //   axios({
  //       method: 'post',
  //       url: config.API_SERVER + '/api/projects',
  //       headers: {
  //         "Content-Type":"application/json",
  //         'Access-Control-Allow-Origin':'*'
  //       },   
  //       data:     { 
  //         pkg: updatedProject
  //       }
  //     }).then(response => {
  //       console.log(response.data);
  //     })

  // };


  const onBlur = (e) =>{
    const newComment = commentBoxRef.current.value;
    setCommentInput(newComment);
    // setIsEditing(false);
    // inputFieldRef.current.value="";
    // updateServer(newTagList);
  }

  const finishTagInput = (e) =>{
    if (e.key==='Enter'){
      //console.log(inputFieldRef.current.value)
      const newComment = commentBoxRef.current.value;
      setCommentInput(newComment);

      addComment();

//       newTagList.push(newTag)
//       // console.log(newTagList)

// //      tagList.push(newTag)
//       //console.log(tagList)
//       setTagList(newTagList);
//       setIsEditing(false);
//       inputFieldRef.current.value="";
//       updateServer(newTagList);

    }
  }

const secondsToTimestamp = (seconds) =>{
   return new Date(seconds*1000).toISOString().slice(11, 19)
    // const hrs = (seconds>360) ?  Math.floor(seconds/360) : 0;
    // const min = (hrs>0) ? Math.floor((seconds-(360*hrs))/60) : 0;
    // const secs = (min>0) ? (seconds-(360*hrs)-(60*min)): Math.floor(seconds);
    // return (((hrs===0)? "00" : hrs) + ":" + ((min===0)? "00" : min) + ":" + ((secs===0)? "00" : secs))
}
const jumpToTime = (goToTime) =>{
  console.log("trying to get to: " + goToTime)
  const videoTracks = document.getElementsByTagName("video");
  videoTracks[0].currentTime=goToTime;

}
const getTimelinePosition = (videoTime,offset) =>{
  const videoTracks = document.getElementsByTagName("video");
  if (videoTracks.length>0){
    const videoLength = videoTracks[0].duration;
    const pos = Math.floor(videoTime/videoLength * 100) - 2;
    return pos + "%";
  } else {
    return "0"
  }

}
const jumpToComment=(commentId)=>{
  const commentDiv = document.getElementById("comment"+ commentId);
  const commentWindow =commentDiv.parentNode;
 // console.log(commentWindow.offsetTop,commentDiv.offsetTop)
  commentWindow.scrollTo({top:(commentDiv.offsetTop),behavior:'smooth'})
}
  const addComment=()=>{
    const videoTracks = document.getElementsByTagName("video");
    const currentTime = videoTracks[0].currentTime;
    console.log(secondsToTimestamp(currentTime))
    console.log(secondsToTimestamp(1503))
    // console.log("Video",videoTracks[0].currentTime)
    //   return;
    // var t= new Date();
    // t.setSeconds(videoTracks[0].currentTime)
    let newComment={
      timestamp:Date.now(),
      commentId: generateGuid(),
      commenter:{
        username: userInfo.nickname,
        userId: userInfo.id,
        avatar: userInfo.picture
      },
      videoTime:videoTracks[0].currentTime,
      body:commentInput
    }
    let updatedProjectContext = {
      ...fileContext
    }
    console.log("NEW",newComment)
    updatedProjectContext.comments.push(newComment)
    commentBoxRef.current.value ='';
    setFileContext(updatedProjectContext)
    updateProject(updatedProjectContext);
  }

const updateProject = (newProjectContext) =>{
    console.log("UPDATING",newProjectContext)
    axios({
      method: "post",
      url: config.API_SERVER + "/api/projects",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        pkg: newProjectContext,
      },
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    async function findFile() {
        // You can await here
        await axios({
          method: "post",
          url: config.API_SERVER + "/api/files/find",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            pkg: {
                userId:userInfo.id,
                fileId:projectId,
                workspaceId:spaceMgr.activeSpace.spaceId
            },
          },
        })
          .then((response) => {
          //  console.log(response.data.files)
            if (response.data.files.length>0){
                setFileContext(response.data.files[0])
              
            } else {
                alert("error finding file")
            }
          })
          .catch((error) => console.log(error));
        // ...
      }
    async function fetchData() {
      // You can await here
      await axios({
        method: "post",
        url: config.API_SERVER + "/api/azure",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          pkg: {},
        },
      })
        .then((response) => {
          setSasKey(response.data.sasKey);
         //  console.log("sasKey",response.data.sasKey)
        })
        .catch((error) => console.log(error));
      // ...
    }

    
    // if (projectId==="new"){
    //     setFileContext({
    //         "projectId": generateGuid(),
    //         "workspaceId":spaceMgr.activeSpace.spaceId,
    //         "userId": userInfo.id,
    //         "title": "New video",
    //         "timestamp": Date.now(),
    //         "mediaUri": "",
    //         "comments": []
    //     })
    // }  else {
    // }

 
    fetchData();
    findFile();


  }, [config.API_SERVER, setSasKey,projectId,spaceMgr,userInfo]);

  const [scrubberStartX,setScrubberStartX] = useState(0)
  const enableScrubbing = (e) =>{
    //console.log("enable scrubbing")
    setScrubberStartX(e.clientX)
    setIsScrubbing(true)
  }
  const disableScrubbing= (e) =>{
    //console.log("disable scrubbing")
    setIsScrubbing(false)
  }
  const scrubberDrag = (e) =>{
    //console.log("dragging " + isScrubbing);
    const scrubber = document.getElementById("scrubber");
    if (isScrubbing){
     scrubber.style.left= scrubberStartX + e.movementX + "px";
      //console.log(e.movementX);
    }
  }

  return (
    <>
    <Breadcrumb style={{height:"auto"}}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to='/projects'>
                Projects
              </BreadcrumbLink>
            </BreadcrumbItem>

          </Breadcrumb>
      <Flex gap="20px" direction='row' p='4'>
          <Flex direction='column' gap='8'>
            
          {/*  */}
          {/* <FileUpload
            onFileToUpload={setFileToUpload}
            onMediaUpload={fileUploaded}
            eventContext={fileContext}
            userContext={userInfo}
            sasKey={sasKey}
          /> */}
          <video ref={videoRef} src={(fileContext && fileContext.mediaUri)?fileContext.mediaUri + "?" +  sasKey:""} controls={true}/>
          <div id='timeline' style={{backgroundColor:"#99999933", position:"relative",height:"16px"}}  onMouseDown={(e)=>enableScrubbing(e)} onMouseUp={(e)=>disableScrubbing(e)} onMouseMove={(e)=>scrubberDrag(e)} >
            <div id='track' style={{zIndex:0,position:"absolute",backgroundColor:"#333",top:"8px",borderRadius:'2px',height:'4px',width:'100%'}}></div>
            <div id="markers"  style={{zIndex:15,position:"absolute",backgroundColor:"#333",top:"8px",borderRadius:'2px',height:'4px',width:'100%'}}>
                {fileContext && fileContext.comments && fileContext.comments.map((item,idx)=>{
                        return(
                        <IconButton  key={idx} variant={'transparent'} onPointerOver={()=>{
                          jumpToComment(item.commentId);
                          setHighlightComment(item.commentId);
                          }} onPointerOut={()=>setHighlightComment(null)} onClick={()=>jumpToTime(item.videoTime)} style={{position:'absolute',top:"8px",left:getTimelinePosition(item.videoTime)}} size='xs' icon={<Image width={'16px'} style={{borderRadius:"8px"}} src={item.commenter.avatar} alt={item.commenter.username}/>}  >
                        </IconButton>                        
                        )
                    })}
            </div>  
            <div id="scrubber" style={{zIndex:32,position:"absolute",backgroundColor:"#333",top:"0",left:"0",borderRadius:'2px',height:'32px',width:'8px'}}></div>             
          </div>

          <div style={{position:'relative'}}>
          <Textarea 
                ref={commentBoxRef}
                onBlur={onBlur} 
                placeholder={'leave a comment at current time'}
                onKeyUp={finishTagInput}
                variant={'outline'}
                resize={'none'}
                ></Textarea>
                <br/>
                <IconButton aria-label='Send' icon={<BiSolidComment />} size='sm'  style={{position:'absolute', zIndex:100, right:'10px',bottom:'10px'}} 
                onClick={()=>addComment()}
                >Send</IconButton>
          </div>

          </Flex>
          <Flex direction={'column'} height={'calc(100vh - 120px)'} minWidth={'320px'} >
            <Heading textTransform={'uppercase'} style={{fontSize:"12px"}}>Comments</Heading>
               
                <Flex  style={{overflowY:'auto',position:'relative'}} py='4' direction='column' height={'100%'} gap='2'>
                {fileContext && fileContext.comments && fileContext.comments.map((item,idx)=>{
                        return(
                        <Flex key={idx} id={"comment" + item.commentId} direction='column' justifyContent='flex-start'>
                            <Card style={{cursor:"pointer",border:(highlightComment===item.commentId)?"1px solid red":"1px solid transparent"}} onClick={()=>jumpToTime(item.videoTime)}>
                        <CardBody style={{padding:"4px 12px",fontSize:"12px"}}>
                            {item.body}
                        </CardBody>
                            </Card>
                            <Flex direction='row' gap='4' justifyContent={'space-between'} alignItems='center' style={{fontSize:'12px'}} px='4' >
                                <Flex justifyContent={'space-between'} gap='2' alignItems='center'>    
                                <Flex direction='row' py='2' gap='4' justifyContent={'space-between'} alignItems='center' style={{fontSize:'12px'}} >
                                  <div style={{height:"16px",width:"16px",backgroundImage:"url("+ item.commenter.avatar + ")",
                                  backgroundSize:"contain",borderRadius:"8px",overflow:"hidden"}}></div>
                                  <div>{item.commenter.username}</div></Flex>
                                <div style={{color:"#999",fontSize:'10px'}}>{moment(item.timestamp).fromNow()}</div>
                                </Flex>
                                <div style={{color:"#999",fontSize:'10px'}}>{secondsToTimestamp(item.videoTime)}</div>
                            </Flex>
                        </Flex>                        
                        )
                    })}
                </Flex>
            
          </Flex>
      </Flex>
    </>
  );
}

export default ProjectDetails;
