import React, {useState} from 'react'
import axios from "axios";
import { useSelector } from 'react-redux';
import { Input,Button, Card } from '@chakra-ui/react';

function AIAssistant(props) {

    const config = useSelector((state)=>state.config.keys);

    const [query,setQuery]=useState("");
    const [AIAnswer, setAIAnswer]= useState(null);
    const askAI = () =>{
        setAIAnswer(null)
        axios({
            method: 'post',
            url: config.API_SERVER + '/api/openai',
            headers: {'Access-Control-Allow-Origin':'*'}, 
            data: {'prompt':  query }//prompt engineering 'summarize this article ' +
        }).then(response => {
              console.log(response.data);
              setAIAnswer(response.data)
              //setSummary(response.data.choices[0].message.content)
              // setCreators(response.data.result)
            })
            .catch(error => console.error(error));
    }
    
    return (
      <>

              Ask Kreeko
              <div style={{display:"flex",flexDirection:"row", gap:"10px",paddingBottom:"20px"}}>
                  <Input size='sm' backgroundColor={'white'} style={{width:"240px"}} type="text" name="searchBox"  id="sb" placeholder="Ask kreeko something" onChange={(e)=>{setQuery(e.currentTarget.value)}} value={query} />
                  <Button size='sm' type="submit" onClick={(e)=>{askAI()}} >Search</Button>
                  </div>
             {AIAnswer && (
                <Card p='4' width="100%">
                    <pre>{AIAnswer.choices[0].message.content}</pre>
                </Card>
             )}
        </>
    )
}

export default AIAssistant;
