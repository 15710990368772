import React from "react";
import { Accordion,AccordionItem, AccordionPanel, AccordionButton,Box,AccordionIcon,Flex, Heading } from "@chakra-ui/react";
import BioHero from "./Bio/Bio_Hero";
import BioSocials from "./Bio/Bio_Socials";
import BioContent from "./Bio/Bio_Content";
import BioAnalytics from "./Bio/Bio_Analytics";
import BioLinks from "./Bio/Bio_Links";
import BioBlurb from "./Bio/Bio_Blurb";
import bioStarter from "./Bio/Bio_Starter.json"
function Bio(props) {

  
  console.log(bioStarter);
    
  return (
    <Flex flexDirection={'row'} wrap={'wrap'} gap={'20px'} p='4'>
          <Flex direction={'column'} minWidth={'320px'} grow={1}>
            <Heading size='xs' textTransform={'uppercase'}>
            Edit options</Heading>
            <Accordion backgroundColor={'white'} borderRadius={'20px'} style={{overflow:'hidden'}}>
              <AccordionItem >
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                  <Heading size='xs'>Profile</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>panel</AccordionPanel>
              </AccordionItem>
              <AccordionItem >
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                  <Heading size='xs'>Links</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>panel</AccordionPanel>
              </AccordionItem>
              <AccordionItem >
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                  <Heading size='xs'>Socials</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>panel</AccordionPanel>
              </AccordionItem>
              <AccordionItem >
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                  <Heading size='xs'>Content</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>panel</AccordionPanel>
              </AccordionItem>
            <AccordionItem >
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                  <Heading size='xs'>Stats</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>panel</AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Flex>
          {/* <Heading size="xs" textTransform="uppercase">
            Landing page
          </Heading> */}
          <Flex direction={'column'} minWidth={'320px'} grow={1}>
          <Heading size='xs' textTransform={'uppercase'}>
            Preview</Heading>
          <Flex direction={'column'} alignItems={'center'} style={{ minWidth:"320px",backgroundColor:"#fff", borderRadius:"12px", overflow:"hidden"}}>
            <BioHero data={bioStarter.global}></BioHero>
            <BioBlurb data={bioStarter.global}></BioBlurb>
            <BioSocials data={bioStarter.socials}></BioSocials>
            <BioContent data={bioStarter.content}></BioContent>
            <BioLinks data={bioStarter.links}></BioLinks>
            <BioAnalytics data={bioStarter.stats}></BioAnalytics>
          </Flex>
          </Flex>
    </Flex>
  );
}

export default Bio;