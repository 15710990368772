import React from "react";
import {Card,CardBody,Flex,Heading,Button, Badge} from "@chakra-ui/react";
// import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
export function Teams() {
  // const creator = useSelector((state)=>state.profile.creatorDetails);
  const {user } = useAuth0();
    return (
      <Flex flexDirection={'column'} gap={20} p='12'>

          <Flex direction="column" gap={5}  alignItems={'center'}>
            <Flex shrink={1}>
              <Heading  size="xs" textTransform="uppercase">Creator</Heading>
            </Flex>
            <Card style={{width:"320px"}}>
              <CardBody>
                  <Flex gap={4} alignItems={'center'}>
                    <img alt="" src={user.picture} style={{width:"48px", height:"48px", borderRadius:"24px",overflow:"hidden"}}/>
                    <Flex alignItems={'flex-start'} direction={'column'}>
                      <div>{user.name}</div>
                      <Badge colorScheme={'blue'}>Creator</Badge>
                    </Flex>
                  </Flex>
              </CardBody>
            </Card>
          </Flex>
          <Flex direction="column" gap={5} alignItems={'center'}>
            <Flex shrink={1}>
              <Heading  size="xs" textTransform="uppercase">Collaborators</Heading>
            </Flex>
            <Flex direction="row" gap={12} wrap={'wrap'} alignItems={'center'} alignContent={'center'}>
            <Card style={{width:"320px"}}>
              <CardBody>
              <Flex gap={4} alignItems={'center'}>
                    <div style={{width:"48px", height:"48px", borderRadius:"24px",overflow:"hidden",backgroundColor:"#efefef"}}></div>
                    <Flex alignItems={'flex-start'} direction={'column'}>
                      <div>Collaborator 1</div>
                      <Badge colorScheme={'purple'}>Collaborator</Badge>
                    </Flex>
                  </Flex>
              </CardBody>
            </Card>

            <Button >Invite</Button>          
            
            </Flex>
          </Flex>
        </Flex>
    )
}


export default Teams;
