// import {Card,CardHeader,CardTitle,CardBody,Row,Col} from "react-bootstrap";
import React from "react";
import { Flex,Heading } from "@chakra-ui/react";
export function BioContent() {
    return (
        <Flex className="BioContent" direction="column" gap={5} style={{width:"100%"}} p='20px' alignItems={'flex-start'}>
          <Flex shrink={1}>
            <Heading  size="xs" textTransform="uppercase">Content</Heading>
          </Flex>
          <Flex shrink={1} gap='5'>
            <div>item</div>
            <div>item</div>
            <div>item</div>
            <div>item</div>
          </Flex>

        </Flex>
    )
}

export default BioContent;
