import React, {useEffect,useState} from 'react'
import axios from "axios";
import 'react-modern-drawer/dist/index.css';

export function AISummary(props) {

  const [summary, setSummary] = useState("Reading...");
  const {feedItem} = props;

  useEffect(() => {
    if (feedItem){

        setSummary("Reading...one sec")
        axios({
            method: 'post',
            url: 'https://openai-wolf-v2.azurewebsites.net/api/myopenai',
            headers: {'Access-Control-Allow-Origin':'*'}, 
            data: {'prompt': 'summarize this article ' + feedItem.hostPageUrl}
        }).then(response => {
              console.log(response.data);
              setSummary(response.data.choices[0].message.content)
              // setCreators(response.data.result)
            })
            .catch(error => console.error(error));
    }
  }, [feedItem,setSummary]);

  return (
<>
<div className="content" style={{padding:"20px"}}>
    {feedItem && (
        <>
            <h4>{feedItem.name}</h4>
            <p>{summary}</p>
        </>
    )}
  </div>
</>
  )
}


// {
//     "id": "chatcmpl-8PeQo5ug8hbjh3ITQaaIpP79W7JFr",
//     "object": "chat.completion",
//     "created": 1701124310,
//     "model": "gpt-3.5-turbo-0613",
//     "choices": [
//       {
//         "index": 0,
//         "message": {
//           "role": "assistant",
//           "content": "This article discusses the importance of explainer videos and provides examples and tips on creating effective ones. It emphasizes that explainer videos are a powerful tool for businesses to convey complex information in a concise and engaging way. The article showcases various types of explainer videos, such as animated, live-action, and whiteboard videos, and explains when to use each type based on their strengths. It also offers tips on scriptwriting, visual design, voiceover selection, and distribution platforms. The article ultimately encourages businesses to consider incorporating explainer videos into their marketing strategies to boost engagement and conversions."
//         },
//         "finish_reason": "stop"
//       }
//     ],
//     "usage": {
//       "prompt_tokens": 29,
//       "completion_tokens": 116,
//       "total_tokens": 145
//     }
//   }