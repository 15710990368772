
import React from "react";
//import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export function OAuth() {

   // const history = useHistory();
    const location = useLocation();

    const getParmFromHash= (url, parm)=>{
        var re = new RegExp("#.*[?&]" + parm + "=([^&]+)(&|$)");
        var match = url.match(re);
        return(match ? match[1] : "");
    }

    useEffect(()=>{

        //history.push("/dashboard");
    },[])

    return (
      <>
      <div className="content">

        OAuth Status<br/>
        {location.hash}<p></p>
        output:{getParmFromHash(window.location.href,"access_token")}
        </div>
        </>
    )
}

export default OAuth;
