import React from "react";
// import loading from "../assets/loading.svg";
// import logo from "../assets/img/logo.svg";
import kreekoType from "../assets/img/kreeko_type.svg";

const Loading = () => (
  <div style={{position:"absolute", height:"100%", width:"100%", padding:"20px",overflow:"hidden"}}>
    <div style={{display:"flex",flexDirection:"column", width:"100%", height:"100%", alignItems:"center",padding:"30px", justifyContent:"center"}}>
                          <img style={{height:"64px"}} src={kreekoType} alt="kreeko" /> 
      </div>
  </div>


);

export default Loading;
