
import React from "react";


export function StripePlans() {
    return (
      <div style={{borderRadius:"25px",overflow:"hidden"}}>
<stripe-pricing-table pricing-table-id="prctbl_1PNLa0GjQknaRGBdD8OJYaAD"
publishable-key="pk_test_51Ni2bJGjQknaRGBdP0r5yxVZiPtouu63M9FumAIAyIgxuCrkqfs9HfmiP8vbDeNTEMACvEondioQpIleBza1Wav700hIkYbd1t">
</stripe-pricing-table>
        </div>
    )
}

export default StripePlans;
