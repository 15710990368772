// import {Card,CardHeader,CardTitle,CardBody,Row,Col} from "react-bootstrap";
import React from "react";
import { Flex } from "@chakra-ui/react";
// import AvatarEditor from "react-avatar-editor";
// import MyAvatarEditor from "../../components/AvatarEditor/MyAvatarEditor";
import AvatarEditor from "react-avatar-editor";
export function BioHero() {
    return (
      <div className="BioHero" style={{position:"relative",width:"100%",height:"280px"}}>
        <div style={{height:"240px",left:0,top:0, right:0,position:"absolute", backgroundColor:"#333",color:"#fff"}}>background header</div>
        <Flex justifyContent={'flex-start'} px='4' alignItems={'center'} direction={'row'} style={{position:'relative',top:"200px",color:"#fff",zIndex:15,filter:"drop-shadow(2px 2px 5px #66666699)"}}>
                {/* <MyAvatarEditor /> */}
                <AvatarEditor                 
                image={"../kreekologo512.png"}
                width={80}
                height={80}
                border={0}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={1}
                rotate={0}
                style={{borderRadius:"5px",zIndex:24,backgroundColor:"#fff"}}/>
            <Flex alignItems={'center'} px='4' style={{position:'relative',height:"40px",backgroundColor:"#666",borderRadius:"0 5px 5px 0"}}>Doug Herman</Flex>
        </Flex>
      </div>
    )
}

export default BioHero;
