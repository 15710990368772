import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  keys: {},
  isLoaded:false,
  SASKey:null
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    updateSAS:(state,action)=>{
     //console.log(action.payload);
      state.SASKey=action.payload;
    },
    updateConfig: (state, action) => {
      //console.log("state updating config", action.payload);
      state.keys = action.payload;
      state.isLoaded= true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateConfig,updateSAS } = configSlice.actions;

export default configSlice.reducer;
