import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  statusGroups: {
    notstarted: [
        { id: "089d0e95-5d6d-4dd7-989b-1887043d983a", title: "First Task", status: "backlog" },
        { id: "604d7fad-bae4-46fd-827c-b23ef75e7d71", title: "Second Task", status: "backlog" },
        { id: "1c8f582e-57ea-4062-9c8c-8ee8fd262e38", title: "Third Task", status: "backlog" },
      ],
    inprogress: [
        { id: "6780d71e-1824-473f-9ee5-a3dd9b51fb44", title: "First Task", status: "backlog" },
        { id: "1bdb9643-c647-4d16-bf65-178c7cc3a46e", title: "Third Task", status: "backlog" },
      ],

    scheduled:[
        { id: "7c4c409c-7266-46f8-a879-bddd550fde6a", title: "First Task", status: "backlog" },
        { id: "1dadbfa2-71d1-409f-8ff2-6690cd2406a0", title: "Third Task", status: "backlog" },
      ],
    uploaded:[
        { id: "b09b6c66-c0d9-437e-a2f3-7f86bbeb9b4e", title: "First Task", status: "backlog" },
        { id: "4618365e-b5b2-4ade-b5c3-1097434674ef", title: "Third Task", status: "backlog" },
      ],
  },
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    updateStatusGroups: (state, action) => {
      console.log("state updating board", action.payload);
      state.statusGroups = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateStatusGroups } = projectSlice.actions;

export default projectSlice.reducer;
