import React from 'react';
class Image extends React.Component {
    state = {
      hasError: false
    };
  
    handleError = (e) => {
        console.log("error loading image");
      this.setState({
        hasError: true
      });
    };
    handleLoad = (e) => {
     // console.log("image loading complete ", e);
    // this.setState({
    //   hasError: true
    // });
  };
    render() {
      if (this.state.hasError) {
        return (<div></div>);
      }
  
      return <img {...this.props} alt={this.props.alt} onLoad={this.handleLoad} onError={this.handleError} />;
    }
  }

  export default Image;