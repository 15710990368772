import React from "react";

export function Privacy() {
    return (
      <>
      <div className="content" style={{padding:"20px", height:"100%",overflow:"auto"}}>
<h2>Privacy Policy </h2>
<p>This privacy policy ("policy") will help you understand how kreeko.co ("us", "we", 
"our") uses and protects the data you provide to us when you visit and use [website 
URL] ("website ", "service").</p>
<p>
We reserve the right to change this policy at any given time, of which you will be 
promptly updated. If you want to make sure that you are up to date with the latest 
changes, we advise you to frequently visit this page. 
</p>
<h4>What User Data We Collect</h4>
<p>When you visit the website, we may collect the following data: 
• Your IP address. 
• Your contact information and email address. 
• Other information such as interests and preferences. 
• Data profile regarding your online behavior on our website. 
</p>
<h4>Why We Collect Your Data</h4>
<p>We are collecting your data for several reasons: 
• To better understand your needs. 
• To improve our services and products. 
• To send you promotional emails containing the information we think you will find 
interesting. 
• To contact you to fill out surveys and participate in other types of market 
research. 
• To customize our website according to your online behavior and personal 
preferences. 
</p>
<h4>Safeguarding and Securing the Data </h4>

<p>kreeko.co is committed to securing your data and keeping it confidential. 
kreeko.co has done all in its power to prevent data theft, unauthorized access, 
and disclosure by implementing the latest technologies and software, which help us 
safeguard all the information we collect online. 
</p>
<h4>Our Cookie Policy</h4> 
<p>Once you agree to allow our website to use cookies, you also agree to use the data it 
collects regarding your online behavior (analyze web traffic, web pages you visit and
spend the most time on). 
</p>
<p>The data we collect by using cookies is used to customize our website to your needs.
Website privacy policy template by WebsitePolicies.com
</p>
<p>After we use the data for statistical analysis, the data is completely removed from our 
systems. 
</p>
<p>Please note that cookies don't allow us to gain control of your computer in any way. 
They are strictly used to monitor which pages you find useful and which you do not so 
that we can provide a better experience for you. 
</p>
<p>If you want to disable cookies, you can do it by accessing the settings of your internet 
browser. You can visit https://www.internetcookies.com, which contains comprehensive 
information on how to do this on a wide variety of browsers and devices. 
</p>
<h4>Links to Other Websites</h4> 
<p>Our website contains links that lead to other websites. If you click on these links 
kreeko.co is not held responsible for your data and privacy protection. Visiting 
those websites is not governed by this privacy policy agreement. Make sure to read the 
privacy policy documentation of the website you go to from our website. 
</p>

<h4>Restricting the Collection of your Personal Data </h4>
<p>At some point, you might wish to restrict the use and collection of your personal data. 
</p>
<p>You can achieve this by doing the following: 
When you are filling the forms on the website, make sure to check if there is a box 
which you can leave unchecked, if you don't want to disclose your personal information. 
If you have already agreed to share your information with us, feel free to contact us via 
email and we will be more than happy to change this for you. 
</p>
<p>kreeko.co will not lease, sell or distribute your personal information to any third 
parties, unless we have your permission. We might do so if the law forces us. Your 
personal information will be used when we need to send you promotional materials if 
you agree to this privacy policy.
</p>     
        </div>
        </>
    )
}

export default Privacy;



