import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: null 
};

export const phylloSlice = createSlice({
  name: "phyllo",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    updatePhylloUser: (state, action) => {
      console.log("UPDATING PHYLLO REDUX STATE", action.payload);
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePhylloUser } = phylloSlice.actions;

export default phylloSlice.reducer;
