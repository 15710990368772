import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: {} 
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    updateAuth: (state, action) => {
      if(action.payload){
        //console.log("state updating auth", action.payload);
        state.user = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAuth } = authSlice.actions;

export default authSlice.reducer;
