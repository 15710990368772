import { createSlice } from '@reduxjs/toolkit'
// import { _ } from 'lodash'
// import axios from "axios";

const initialState = {
  socials: [],
  hasCreatorInfo:false,
  creatorDetails:{topics:[]},
  isAuthenticated:false,
  userId:""
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {

    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    setUserId:(state,action)=>{
     // console.log("updating user id",action.payload)
        state.userId = action.payload
    },
    setIsAuthenticated:(state, action) =>{
        state.isAuthenticated = action.payload
    },
    refreshSocials:(state,action)=> {
      // const sub = action.payload;
      state.socials = action.payload
    },
    updateCreatorDetails:(state,action)=>{
      if (action.payload!==undefined){
        if (action.payload.userId){
          state.creatorDetails = action.payload
          // console.log("redux : ", action.payload);
          if (action.payload.topics){
            state.hasCreatorInfo = true;
          } else {
            state.hasCreatorInfo= false;
          }
        }
      }
    },

    updateSocials:(state, action) => {
        console.log("updating socials STATE:",action.payload)
       var newSocials = action.payload.socials;
      //  var reports = action.payload.reports;
      // var newTopics = action.payload.topics;
      // if (newTopics.length>0){
      //   state.topics = newTopics[0].topics;
      // }
      //  for (var i=0;i<newSocials.length;i++){
      //   let uuid= newSocials[i].userId;
      //   let serviceId = newSocials[i].serviceId;
      //   let selReports = reports.filter((item,idx)=>{
      //     return (item.serviceId === serviceId && item.userId===uuid)
      //   })
      //   newSocials[i].reports = selReports;
      //  }
       state.socials = newSocials;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserId, setIsAuthenticated, refreshSocials, updateSocials,updateCreatorDetails,updateTopics } = profileSlice.actions

export default profileSlice.reducer