
import React from "react";
import { Flex } from "@chakra-ui/react";
export function Hero() {
    return (
        <Flex justifyContent={'center'} alignItems={'center'} height="400px">
              Landing page
        </Flex>
    )
}

export default Hero;
