// import {Card,CardHeader,CardTitle,CardBody,Row,Col} from "react-bootstrap";
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
export function BioBlurb() {
    return (
        <Flex className="BioBlurb" direction="column" gap={5} style={{width:"100%"}} p='20px' alignItems={'flex-start'}>
          <Text>this is a short description...blah blah blah</Text>
        </Flex>
    )
}

export default BioBlurb;