import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { pokemonApi } from './services/pokemon'
// import { feedApi } from './services/feedSearch';
import theFeedReducer from './features/feed/theFeedSlice';
import profileReducer from './features/profile/profileSlice';
import projectsReducer from './features/projects/projectSlice';
import configReducer from './features/config/configSlice';
import authReducer from './features/auth/authSlice';
import spacesSlice from './features/spaces/spacesSlice';
import phylloReducer from './features/phyllo/phylloSlice';

export const store = configureStore({
  reducer: {

    theFeed: theFeedReducer,
    profile: profileReducer,
    projects:projectsReducer,
    spaceMgr: spacesSlice,
    auth:authReducer,
    phyllo:phylloReducer,
    config:configReducer,
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    // [feedApi.reducerPath]:feedApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(pokemonApi.middleware)
      // .concat(feedApi.middleware),

})

setupListeners(store.dispatch);